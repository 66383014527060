

<template>

    <div class="overlay bg-black/70 w-[100vw] h-[100vh] fixed z-10 top-0 left-0">
        <div class="loading-animation text-center text-white text-sm absolute top-[50%] left-[50%] w-[300px] h-[200px] -mt-[100px] -ml-[100px]">
            <div class="btn-loader w-20 h-20 block"></div>   
            <div class="block"><span>Loading data from server...</span><br /><span class="text-xs">Note: This may take several seconds depending on the date range size selected.</span>
            </div>   
        </div>
    </div>

  </template>
  
  <style scoped>

  
  </style>

<script setup>



</script>