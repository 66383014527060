import * as sh from '@/scripts/stringhelpers';
// All of the parameters delivered by a Meter
export function getParameters() {

    var parameters = [];
    for (var item of elementParams) for (var a of alphaElems)
    parameters.push(item + a);
    for (var item of channelParams) for (var elem of alphaElems)
        for (var i of paramChs) parameters.push(item + elem + i);
    for (var item of extraParams) parameters.push(item);
    return parameters;
}

export function getMeterParameters(meterSerial) {

    
    let elCnt = getElementCount(meterSerial);


    if(elCnt == 0)
        return [];

    // Initialize an array to store parameters
    var parameters = [];

    let tAlphaElems = [...alphaElems];

    tAlphaElems = keepNElements(tAlphaElems,elCnt);
    
    // Combine element parameters with alpha elements
    for (var item of elementParams) {
        for (var a of tAlphaElems) {
            parameters.push(item + a);
        }
    }

    // Combine channel parameters with alpha elements and parameter channels
    for (var item of channelParams) {
        for (var elem of tAlphaElems) {
            for (var i of paramChs) {
                parameters.push(item + elem + i);
            }
        }
    }

    // Add extra parameters to the array
    for (var item of extraParams) {
        parameters.push(item);
    }

    // Return the combined parameters
    return parameters;

}

function keepNElements(array, N) {
    // Check if N is a valid number
    if (typeof N !== 'number' || N < 0) {
        throw new Error('N must be a non-negative number.');
    }

    // Check if the array is already shorter than N
    if (array.length <= N) {
        return array.slice(); // Return a copy of the original array
    }

    // Return a new array containing only the first N elements
    return array.slice(0, N);
}

export const getMeterType = (meterSerial) => { 
   
    if(sh.isNullOrEmpty(meterSerial))
        return 0;
    else{
        
        let meterType = parseInt(meterSerial.substring(1,3));

        if(isNaN(meterType))
            return 0; 
        else return meterType;   
    }
}

export const getElementCount = (meterSerial) => { 
   
    if(sh.isNullOrEmpty(meterSerial))
        return 0;
    else{
        
        let meterType = parseInt(meterSerial.substring(1,3));

        if(isNaN(meterType))
            return 0;
        else    
            return meterType/3; 
    }
} 

const alphaElems = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];


const paramChs = ["1", "2", "3"];

const elementParams = [
    "kVAHNet/Elm/",
    "kVARHNet/Elm/",
    // "P/Elm/",
    "kWHNet/Elm/",
    // "aPF/Elm/",
    "dPF/Elm/",
    "DemandkW/Elm/",
    "DemandkWPeak/Elm/",
];

const channelParams = [
    "A/Ch/",
    "dPF/Ch/",
];

const extraParams = [
    // "LineFreq/Sys/",
    "V/Ch/Vin1L1L2",
    "V/Ch/Vin1L1N",
    "V/Ch/Vin1L2L3",
    "V/Ch/Vin1L2N",
    "V/Ch/Vin1L3L1",
    "V/Ch/Vin1L3N", 
    "V/Ch/Vin2L1L2",
    "V/Ch/Vin2L1N",
    "V/Ch/Vin2L2L3",
    "V/Ch/Vin2L2N",
    "V/Ch/Vin2L3L1",
    "V/Ch/Vin2L3N",
];

export const getHeaderTranslationByField = (field) => {

    // Iterate over each object in the array
    for (let i = 0; i < headerTranslation.length; i++) {
        // Check if the displayName matches the desired value
        if (headerTranslation[i].field === field) {
            // If found, return the object
            return headerTranslation[i];
        }
    }
    // If not found, return null
    return null;

}

export const headerTranslation = 
    
        [{
            displayName: "kVAHNet\nElement A",
            field: "kVAHNet/Elm/A"
          }, {
            displayName: "kVAHNet\nElement B",
            field: "kVAHNet/Elm/B"
          }, {
            displayName: "kVAHNet\nElement C",
            field: "kVAHNet/Elm/C"
          }, {
            displayName: "kVAHNet\nElement D",
            field: "kVAHNet/Elm/D"
          }, {
            displayName: "kVAHNet\nElement E",
            field: "kVAHNet/Elm/E"
          }, {
            displayName: "kVAHNet\nElement F",
            field: "kVAHNet/Elm/F"
          }, {
            displayName: "kVAHNet\nElement G",
            field: "kVAHNet/Elm/G"
          }, {
            displayName: "kVAHNet\nElement H",
            field: "kVAHNet/Elm/H"
          }, {
            displayName: "kVAHNet\nElement I",
            field: "kVAHNet/Elm/I"
          }, {
            displayName: "kVAHNet\nElement J",
            field: "kVAHNet/Elm/J"
          }, {
            displayName: "kVAHNet\nElement K",
            field: "kVAHNet/Elm/K"
          }, {
            displayName: "kVAHNet\nElement L",
            field: "kVAHNet/Elm/L"
          }, {
            displayName: "kVAHNet\nElement M",
            field: "kVAHNet/Elm/M"
          }, {
            displayName: "kVAHNet\nElement N",
            field: "kVAHNet/Elm/N"
          }, {
            displayName: "kVAHNet\nElement O",
            field: "kVAHNet/Elm/O"
          }, {
            displayName: "kVAHNet\nElement P",
            field: "kVAHNet/Elm/P"
          }, {
            displayName: "kVARHNet\nElement A",
            field: "kVARHNet/Elm/A"
          }, {
            displayName: "kVARHNet\nElement B",
            field: "kVARHNet/Elm/B"
          }, {
            displayName: "kVARHNet\nElement C",
            field: "kVARHNet/Elm/C"
          }, {
            displayName: "kVARHNet\nElement D",
            field: "kVARHNet/Elm/D"
          }, {
            displayName: "kVARHNet\nElement E",
            field: "kVARHNet/Elm/E"
          }, {
            displayName: "kVARHNet\nElement F",
            field: "kVARHNet/Elm/F"
          }, {
            displayName: "kVARHNet\nElement G",
            field: "kVARHNet/Elm/G"
          }, {
            displayName: "kVARHNet\nElement H",
            field: "kVARHNet/Elm/H"
          }, {
            displayName: "kVARHNet\nElement I",
            field: "kVARHNet/Elm/I"
          }, {
            displayName: "kVARHNet\nElement J",
            field: "kVARHNet/Elm/J"
          }, {
            displayName: "kVARHNet\nElement K",
            field: "kVARHNet/Elm/K"
          }, {
            displayName: "kVARHNet\nElement L",
            field: "kVARHNet/Elm/L"
          }, {
            displayName: "kVARHNet\nElement M",
            field: "kVARHNet/Elm/M"
          }, {
            displayName: "kVARHNet\nElement N",
            field: "kVARHNet/Elm/N"
          }, {
            displayName: "kVARHNet\nElement O",
            field: "kVARHNet/Elm/O"
          }, {
            displayName: "kVARHNet\nElement P",
            field: "kVARHNet/Elm/P"
          }, {
            displayName: "kWHNet\nElement A",
            field: "kWHNet/Elm/A"
          }, {
            displayName: "kWHNet\nElement B",
            field: "kWHNet/Elm/B"
          }, {
            displayName: "kWHNet\nElement C",
            field: "kWHNet/Elm/C"
          }, {
            displayName: "kWHNet\nElement D",
            field: "kWHNet/Elm/D"
          }, {
            displayName: "kWHNet\nElement E",
            field: "kWHNet/Elm/E"
          }, {
            displayName: "kWHNet\nElement F",
            field: "kWHNet/Elm/F"
          }, {
            displayName: "kWHNet\nElement G",
            field: "kWHNet/Elm/G"
          }, {
            displayName: "kWHNet\nElement H",
            field: "kWHNet/Elm/H"
          }, {
            displayName: "kWHNet\nElement I",
            field: "kWHNet/Elm/I"
          }, {
            displayName: "kWHNet\nElement J",
            field: "kWHNet/Elm/J"
          }, {
            displayName: "kWHNet\nElement K",
            field: "kWHNet/Elm/K"
          }, {
            displayName: "kWHNet\nElement L",
            field: "kWHNet/Elm/L"
          }, {
            displayName: "kWHNet\nElement M",
            field: "kWHNet/Elm/M"
          }, {
            displayName: "kWHNet\nElement N",
            field: "kWHNet/Elm/N"
          }, {
            displayName: "kWHNet\nElement O",
            field: "kWHNet/Elm/O"
          }, {
            displayName: "kWHNet\nElement P",
            field: "kWHNet/Elm/P"
          }, {
            displayName: "DemandkW\nElement A",
            field: "DemandkW/Elm/A"
          }, {
            displayName: "DemandkW\nElement B",
            field: "DemandkW/Elm/B"
          }, {
            displayName: "DemandkW\nElement C",
            field: "DemandkW/Elm/C"
          }, {
            displayName: "DemandkW\nElement D",
            field: "DemandkW/Elm/D"
          }, {
            displayName: "DemandkW\nElement E",
            field: "DemandkW/Elm/E"
          }, {
            displayName: "DemandkW\nElement F",
            field: "DemandkW/Elm/F"
          }, {
            displayName: "DemandkW\nElement G",
            field: "DemandkW/Elm/G"
          }, {
            displayName: "DemandkW\nElement H",
            field: "DemandkW/Elm/H"
          }, {
            displayName: "DemandkW\nElement I",
            field: "DemandkW/Elm/I"
          }, {
            displayName: "DemandkW\nElement J",
            field: "DemandkW/Elm/J"
          }, {
            displayName: "DemandkW\nElement K",
            field: "DemandkW/Elm/K"
          }, {
            displayName: "DemandkW\nElement L",
            field: "DemandkW/Elm/L"
          }, {
            displayName: "DemandkW\nElement M",
            field: "DemandkW/Elm/M"
          }, {
            displayName: "DemandkW\nElement N",
            field: "DemandkW/Elm/N"
          }, {
            displayName: "DemandkW\nElement O",
            field: "DemandkW/Elm/O"
          }, {
            displayName: "DemandkW\nElement P",
            field: "DemandkW/Elm/P"
          }, {
            displayName: "DemandkWPeak\nElement A",
            field: "DemandkWPeak/Elm/A"
          }, {
            displayName: "DemandkWPeak\nElement B",
            field: "DemandkWPeak/Elm/B"
          }, {
            displayName: "DemandkWPeak\nElement C",
            field: "DemandkWPeak/Elm/C"
          }, {
            displayName: "DemandkWPeak\nElement D",
            field: "DemandkWPeak/Elm/D"
          }, {
            displayName: "DemandkWPeak\nElement E",
            field: "DemandkWPeak/Elm/E"
          }, {
            displayName: "DemandkWPeak\nElement F",
            field: "DemandkWPeak/Elm/F"
          }, {
            displayName: "DemandkWPeak\nElement G",
            field: "DemandkWPeak/Elm/G"
          }, {
            displayName: "DemandkWPeak\nElement H",
            field: "DemandkWPeak/Elm/H"
          }, {
            displayName: "DemandkWPeak\nElement I",
            field: "DemandkWPeak/Elm/I"
          }, {
            displayName: "DemandkWPeak\nElement J",
            field: "DemandkWPeak/Elm/J"
          }, {
            displayName: "DemandkWPeak\nElement K",
            field: "DemandkWPeak/Elm/K"
          }, {
            displayName: "DemandkWPeak\nElement L",
            field: "DemandkWPeak/Elm/L"
          }, {
            displayName: "DemandkWPeak\nElement M",
            field: "DemandkWPeak/Elm/M"
          }, {
            displayName: "DemandkWPeak\nElement N",
            field: "DemandkWPeak/Elm/N"
          }, {
            displayName: "DemandkWPeak\nElement O",
            field: "DemandkWPeak/Elm/O"
          }, {
            displayName: "DemandkWPeak\nElement P",
            field: "DemandkWPeak/Elm/P"
          }, {
            displayName: "Current\nElement A/Ch 1",
            field: "A/Ch/A1"
          }, {
            displayName: "Current\nElement A/Ch 2",
            field: "A/Ch/A2"
          }, {
            displayName: "Current\nElement A/Ch 3",
            field: "A/Ch/A3"
          }, {
            displayName: "Current\nElement B/Ch 1",
            field: "A/Ch/B1"
          }, {
            displayName: "Current\nElement B/Ch 2",
            field: "A/Ch/B2"
          }, {
            displayName: "Current\nElement B/Ch 3",
            field: "A/Ch/B3"
          }, {
            displayName: "Current\nElement C/Ch 1",
            field: "A/Ch/C1"
          }, {
            displayName: "Current\nElement C/Ch 2",
            field: "A/Ch/C2"
          }, {
            displayName: "Current\nElement C/Ch 3",
            field: "A/Ch/C3"
          }, {
            displayName: "Current\nElement D/Ch 1",
            field: "A/Ch/D1"
          }, {
            displayName: "Current\nElement D/Ch 2",
            field: "A/Ch/D2"
          }, {
            displayName: "Current\nElement D/Ch 3",
            field: "A/Ch/D3"
          }, {
            displayName: "Current\nElement E/Ch 1",
            field: "A/Ch/E1"
          }, {
            displayName: "Current\nElement E/Ch 2",
            field: "A/Ch/E2"
          }, {
            displayName: "Current\nElement E/Ch 3",
            field: "A/Ch/E3"
          }, {
            displayName: "Current\nElement F/Ch 1",
            field: "A/Ch/F1"
          }, {
            displayName: "Current\nElement F/Ch 2",
            field: "A/Ch/F2"
          }, {
            displayName: "Current\nElement F/Ch 3",
            field: "A/Ch/F3"
          }, {
            displayName: "Current\nElement G/Ch 1",
            field: "A/Ch/G1"
          }, {
            displayName: "Current\nElement G/Ch 2",
            field: "A/Ch/G2"
          }, {
            displayName: "Current\nElement G/Ch 3",
            field: "A/Ch/G3"
          }, {
            displayName: "Current\nElement H/Ch 1",
            field: "A/Ch/H1"
          }, {
            displayName: "Current\nElement H/Ch 2",
            field: "A/Ch/H2"
          }, {
            displayName: "Current\nElement H/Ch 3",
            field: "A/Ch/H3"
          }, {
            displayName: "Current\nElement I/Ch 1",
            field: "A/Ch/I1"
          }, {
            displayName: "Current\nElement I/Ch 2",
            field: "A/Ch/I2"
          }, {
            displayName: "Current\nElement I/Ch 3",
            field: "A/Ch/I3"
          }, {
            displayName: "Current\nElement J/Ch 1",
            field: "A/Ch/J1"
          }, {
            displayName: "Current\nElement J/Ch 2",
            field: "A/Ch/J2"
          }, {
            displayName: "Current\nElement J/Ch 3",
            field: "A/Ch/J3"
          }, {
            displayName: "Current\nElement K/Ch 1",
            field: "A/Ch/K1"
          }, {
            displayName: "Current\nElement K/Ch 2",
            field: "A/Ch/K2"
          }, {
            displayName: "Current\nElement K/Ch 3",
            field: "A/Ch/K3"
          }, {
            displayName: "Current\nElement L/Ch 1",
            field: "A/Ch/L1"
          }, {
            displayName: "Current\nElement L/Ch 2",
            field: "A/Ch/L2"
          }, {
            displayName: "Current\nElement L/Ch 3",
            field: "A/Ch/L3"
          }, {
            displayName: "Current\nElement M/Ch 1",
            field: "A/Ch/M1"
          }, {
            displayName: "Current\nElement M/Ch 2",
            field: "A/Ch/M2"
          }, {
            displayName: "Current\nElement M/Ch 3",
            field: "A/Ch/M3"
          }, {
            displayName: "Current\nElement N/Ch 1",
            field: "A/Ch/N1"
          }, {
            displayName: "Current\nElement N/Ch 2",
            field: "A/Ch/N2"
          }, {
            displayName: "Current\nElement N/Ch 3",
            field: "A/Ch/N3"
          }, {
            displayName: "Current\nElement O/Ch 1",
            field: "A/Ch/O1"
          }, {
            displayName: "Current\nElement O/Ch 2",
            field: "A/Ch/O2"
          }, {
            displayName: "Current\nElement O/Ch 3",
            field: "A/Ch/O3"
          }, {
            displayName: "Current\nElement P/Ch 1",
            field: "A/Ch/P1"
          }, {
            displayName: "Current\nElement P/Ch 2",
            field: "A/Ch/P2"
          }, {
            displayName: "Current\nElement P/Ch 3",
            field: "A/Ch/P3"
          }, {
            displayName: "Power Factor\nElement A/Ch 1",
            field: "dPF/Ch/A1"
          }, {
            displayName: "Power Factor\nElement A/Ch 2",
            field: "dPF/Ch/A2"
          }, {
            displayName: "Power Factor\nElement A/Ch 3",
            field: "dPF/Ch/A3"
          }, {
            displayName: "Power Factor\nElement B/Ch 1",
            field: "dPF/Ch/B1"
          }, {
            displayName: "Power Factor\nElement B/Ch 2",
            field: "dPF/Ch/B2"
          }, {
            displayName: "Power Factor\nElement B/Ch 3",
            field: "dPF/Ch/B3"
          }, {
            displayName: "Power Factor\nElement C/Ch 1",
            field: "dPF/Ch/C1"
          }, {
            displayName: "Power Factor\nElement C/Ch 2",
            field: "dPF/Ch/C2"
          }, {
            displayName: "Power Factor\nElement C/Ch 3",
            field: "dPF/Ch/C3"
          }, {
            displayName: "Power Factor\nElement D/Ch 1",
            field: "dPF/Ch/D1"
          }, {
            displayName: "Power Factor\nElement D/Ch 2",
            field: "dPF/Ch/D2"
          }, {
            displayName: "Power Factor\nElement D/Ch 3",
            field: "dPF/Ch/D3"
          }, {
            displayName: "Power Factor\nElement E/Ch 1",
            field: "dPF/Ch/E1"
          }, {
            displayName: "Power Factor\nElement E/Ch 2",
            field: "dPF/Ch/E2"
          }, {
            displayName: "Power Factor\nElement E/Ch 3",
            field: "dPF/Ch/E3"
          }, {
            displayName: "Power Factor\nElement F/Ch 1",
            field: "dPF/Ch/F1"
          }, {
            displayName: "Power Factor\nElement F/Ch 2",
            field: "dPF/Ch/F2"
          }, {
            displayName: "Power Factor\nElement F/Ch 3",
            field: "dPF/Ch/F3"
          }, {
            displayName: "Power Factor\nElement G/Ch 1",
            field: "dPF/Ch/G1"
          }, {
            displayName: "Power Factor\nElement G/Ch 2",
            field: "dPF/Ch/G2"
          }, {
            displayName: "Power Factor\nElement G/Ch 3",
            field: "dPF/Ch/G3"
          }, {
            displayName: "Power Factor\nElement H/Ch 1",
            field: "dPF/Ch/H1"
          }, {
            displayName: "Power Factor\nElement H/Ch 2",
            field: "dPF/Ch/H2"
          }, {
            displayName: "Power Factor\nElement H/Ch 3",
            field: "dPF/Ch/H3"
          }, {
            displayName: "Power Factor\nElement I/Ch 1",
            field: "dPF/Ch/I1"
          }, {
            displayName: "Power Factor\nElement I/Ch 2",
            field: "dPF/Ch/I2"
          }, {
            displayName: "Power Factor\nElement I/Ch 3",
            field: "dPF/Ch/I3"
          }, {
            displayName: "Power Factor\nElement J/Ch 1",
            field: "dPF/Ch/J1"
          }, {
            displayName: "Power Factor\nElement J/Ch 2",
            field: "dPF/Ch/J2"
          }, {
            displayName: "Power Factor\nElement J/Ch 3",
            field: "dPF/Ch/J3"
          }, {
            displayName: "Power Factor\nElement K/Ch 1",
            field: "dPF/Ch/K1"
          }, {
            displayName: "Power Factor\nElement K/Ch 2",
            field: "dPF/Ch/K2"
          }, {
            displayName: "Power Factor\nElement K/Ch 3",
            field: "dPF/Ch/K3"
          }, {
            displayName: "Power Factor\nElement L/Ch 1",
            field: "dPF/Ch/L1"
          }, {
            displayName: "Power Factor\nElement L/Ch 2",
            field: "dPF/Ch/L2"
          }, {
            displayName: "Power Factor\nElement L/Ch 3",
            field: "dPF/Ch/L3"
          }, {
            displayName: "Power Factor\nElement M/Ch 1",
            field: "dPF/Ch/M1"
          }, {
            displayName: "Power Factor\nElement M/Ch 2",
            field: "dPF/Ch/M2"
          }, {
            displayName: "Power Factor\nElement M/Ch 3",
            field: "dPF/Ch/M3"
          }, {
            displayName: "Power Factor\nElement N/Ch 1",
            field: "dPF/Ch/N1"
          }, {
            displayName: "Power Factor\nElement N/Ch 2",
            field: "dPF/Ch/N2"
          }, {
            displayName: "Power Factor\nElement N/Ch 3",
            field: "dPF/Ch/N3"
          }, {
            displayName: "Power Factor\nElement O/Ch 1",
            field: "dPF/Ch/O1"
          }, {
            displayName: "Power Factor\nElement O/Ch 2",
            field: "dPF/Ch/O2"
          }, {
            displayName: "Power Factor\nElement O/Ch 3",
            field: "dPF/Ch/O3"
          }, {
            displayName: "Power Factor\nElement P/Ch 1",
            field: "dPF/Ch/P1"
          }, {
            displayName: "Power Factor\nElement P/Ch 2",
            field: "dPF/Ch/P2"
          }, {
            displayName: "Power Factor\nElement P/Ch 3",
            field: "dPF/Ch/P3"
          }, {
            displayName: "Volts\nInput 1/L1-L2",
            field: "V/Ch/Vin1L1L2"
          }, {
            displayName: "Volts\nInput 1/L1-N",
            field: "V/Ch/Vin1L1N"
          }, {
            displayName: "Volts\nInput 1/L2-L3",
            field: "V/Ch/Vin1L2L3"
          }, {
            displayName: "Volts\nInput 1L2-N",
            field: "V/Ch/Vin1L2N"
          }, {
            displayName: "Volts\nInput 1/L3-L1",
            field: "V/Ch/Vin1L3L1"
          }, {
            displayName: "Volts\nInput 1/L3-N",
            field: "V/Ch/Vin1L3N"
          }, {
            displayName: "Volts\nInput 2/L1-L2",
            field: "V/Ch/Vin2L1L2"
          }, {
            displayName: "Volts\nInput 2/L1-N",
            field: "V/Ch/Vin2L1N"
          }, {
            displayName: "Volts\nInput 2/L2-L3",
            field: "V/Ch/Vin2L2L3"
          }, {
            displayName: "Volts\nInput 2/L2-N",
            field: "V/Ch/Vin2L2N"
          }, {
            displayName: "Volts\nInput 2/L3-L1",
            field: "V/Ch/Vin2L3L1"
          }, {
            displayName: "Volts\nInput 2/L3-N",
            field: "V/Ch/Vin2L3N"
          }]
            
