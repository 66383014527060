<template>
    <button
      :type="type"
      class="
        px-6
        py-2        
        text-white
        text-sm       
        rounded-md
        transition
        hover:opacity-90
        focus:outline-none">
      <slot />
    </button>
  </template>
  
  <script>
  export default {
    name: 'Button',
    props: {
      type: {
        type: String,
        default: 'save',
      },
    },
  };
  </script>