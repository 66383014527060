import store from "@/framework/store";


const SECRET_KEY = process.env.VUE_APP_ENCRYPTION_KEY;
const ENDPOINT = process.env.VUE_APP_GATEWAY_API_ENDPOINT;
var CryptoJS = require("crypto-js");
// var xmlHttp = null;

export async function http_GatewayAPI(params, ipAddress, jwtToken, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4)
      httpCallback(callback, xmlHttp.responseText, xmlHttp.status);
    // if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
    //   callBackFunction(callback, xmlHttp.responseText);
    // else if ([401, 403].includes(xmlHttp.status))
    //   errBackFunction(callback, { error: xmlHttp.status });
  };
  xmlHttp.timeout = 600000; // timeout 10 minutes
  xmlHttp.open("GET", ENDPOINT, true); // true for asynchronous
  xmlHttp.setRequestHeader("Authorization", jwtToken);
  xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xmlHttp.setRequestHeader(
    "Parameters",
    await encryptParams(JSON.stringify(params))
  );
  // xmlHttp.setRequestHeader("Encryption", await encryptIP(ipAddress));
  xmlHttp.setRequestHeader("Encryption", "FAKEIP");
  xmlHttp.send();

  return xmlHttp.onreadystatechange;
}

export async function http_AbortRequest() {
  try {
    xmlHttp.abort();
    return true;
  } catch (error) {
    return false;
  }
}

export async function getEncryptIp(IP) {
  return await encryptIP(IP);
}
async function encryptIP(IP) {
  // Based on the IP Address
  const IPSplit = IP.split("."); // Encrypt by variable IP
  const rounds = Math.floor(parseInt(IPSplit[0], 10) / 10);

  var mods = [];
  for (var m = 0; m < rounds - 1; m++) mods.push(m % 4);

  // Encryption Method
  var ciphertext = CryptoJS.AES.encrypt(IP, SECRET_KEY).toString();
  var ct = [ciphertext.substring(10)]; // omit the salt
  for (var i = 0; i < mods.length; i++) {
    const encryptKey = SECRET_KEY + String(mods[i]);
    const crypti = CryptoJS.AES.encrypt(ct[i], encryptKey).toString();
    ct.push(crypti.substring(10)); // omit the salt
  }
  return ct[ct.length - 1];
}

export async function getEncryptParams(params) {
  return await encryptParams(params);
}
async function encryptParams(params) {
  const crypto = CryptoJS.AES.encrypt(params, SECRET_KEY)
    .toString()
    .substring(10); // remove salt
  var cryptArray = crypto.split("");
  for (var i = 0; i < cryptArray.length - 2; i++) {
    const temp = cryptArray[i];
    if (i % 2) {
      cryptArray[i] = cryptArray[i + 2];
      cryptArray[i + 2] = temp;
    } else {
      cryptArray[i] = cryptArray[i + 1];
      cryptArray[i + 1] = temp;
    }
  }
  return cryptArray.reverse().join("");
}

function callBackFunction(callback, responseText) {
  if (typeof responseText === "object" && "body" in responseText)
    callback(JSON.parse(responseText).body);
  else callback(JSON.parse(responseText));
}

function errBackFunction(callback, responseText) {
  callback(responseText);
}

async function httpCallback(callback, response, status) {
  // const size = getPacketSize(response);
  // console.log("packet size:", size, "(max 6.00 MB)");
  
  var callbackResp = {};
  try {
    if (typeof response === "object" && "body" in response)
      callbackResp = JSON.parse(JSON.parse(response).body);
    else callbackResp = JSON.parse(JSON.parse(response));
  } catch (error) {
    try {
      callbackResp.response = JSON.parse(response);
    } catch (error) {
      callbackResp.response = { abort: true, success: false };
    }
    callback.error = error;
  }
  if (callbackResp == null)  {
    callback({success: false, response: response});
    return response;
  }
  
  // Handle Bad Access Requests // Caught FORBIDDEN or Unauthorized
  const statusCode = "statusCode" in callbackResp ? callbackResp.statusCode : status;
  if (statusCode == 403 || statusCode == 401) store.dispatch('expireState');

  const success = "success" in callbackResp ? callbackResp.success 
                : status == 200 ? true : false;

  callbackResp.statusCode = status;
  callbackResp.success = success;

  if ("url" in callbackResp && callbackResp.response == 's3_getFile') {
    const content = await fetch(callbackResp.url).then(async (r) => {
      return r.text().then((d) => { return JSON.parse(d) });
    });
    callback(JSON.parse(JSON.stringify(content)));
    return content;
  }
  else callback(callbackResp);
}

function getPacketSize(packet) {
  const blob = new Blob([packet]).size;
  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }
  return formatBytes(blob);
}
