import { createApp } from 'vue/dist/vue.esm-bundler';

import App from '@/App.vue'
const app = createApp(App);

// storage and navigation
import router from '@/framework/router.js'
import store from '@/framework/store.js'


//styles
import './styles/app.css'; // tailwind
import 'gitart-vue-dialog/dist/style.css'

/**General Element components */
import DentLogo from "@/components/elements/vdentlogo.vue";
import DentCloudLogo from "@/components/elements/vdentcloudlogo.vue";
import Button from "@/components/elements/vbutton.vue";
import Input from "@/components/elements/vtextinput.vue";
import PageLoading from "@/components/elements/vpageloading.vue";
import PulseIcon from "@/components/elements/vpulseicon.vue";
import SkeletonLoader from "@/components/elements/vskeletonloader.vue";

// main.js
import { GDialogRoot } from 'gitart-vue-dialog'
import { GDialog } from 'gitart-vue-dialog'

/* Nav components */
import TopNav from "@/components/nav/topnav.vue";
import LeftNav from "@/components/nav/leftnav.vue";
import ChildNav from "@/components/nav/childNav.vue";
import ProfileAvatar from "@/components/auth/vprofilelink.vue";
import Stencil from "@/components/elements/vstencil.vue";
import AvatarCropper from "@/components/elements/vcropper.vue";

/* Page Template components */
import PageTemplate from "@/components/page/pagetemplate.vue";
import Breadcrumb from "@/components/elements/vbreadcrumb.vue";


/*Dashboard components */
import AccumulatedValueWidget from "@/components/dashboard/vvaluedashboarddata.vue";
import GraphedValueWidget from "@/components/dashboard/vgrapheddashboarddata.vue";
import DataViewWidget from "@/components/dashboard/vdataviewwidget.vue";
import NewDataView from "@/views/dashboards/new-dashboard.vue";
import EChart from "@/components/dashboard/vechart.vue";



app.config.warnHandler = () => null; // NOTE: Disables all annoyances
app.component("DentLogo", DentLogo)
.component("DentCloudLogo", DentCloudLogo)
    .component("TopNav", TopNav)
    .component("Button", Button)
    .component("LeftNav", LeftNav)
    .component("Input", Input)
    .component("PageTemplate", PageTemplate)
    .component("ProfileAvatar", ProfileAvatar)
    .component("Breadcrumb", Breadcrumb)
    .component("PageLoading", PageLoading)
    .component("AccumulatedValueWidget", AccumulatedValueWidget)
    .component("GraphedValueWidget", GraphedValueWidget)
    .component("GDialog", GDialog)
    .component("GDialogRoot", GDialogRoot)
    .component("PulseIcon",PulseIcon)
    .component("DataViewWidget", DataViewWidget)
    .component("NewDataView", NewDataView)
    .component("Stencil", Stencil)
    .component("AvatarCropper", AvatarCropper)
    .component("ChildNav", ChildNav)
    .component("EChart", EChart)
    .component("SkeletonLoader",SkeletonLoader)

app.config.globalProperties.emailValidationRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
app.config.globalProperties.CompanyName = "DENT Instruments, Inc.";

const pbarOptions = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
      speed: "0.2s",
      opacity: "0.6s",
      termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
  };

app.use(store).use(router).mount('#app')
