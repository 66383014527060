
export const meterParameterOptions_v2 = [    
  {
    name: 'dPF',
    displayname:'dPF',
    description: 'Displacement Power Factor (Element)',    
    by:'Elm',
    type:'instantaneous',
    disabled:true,
    uom:'dPF',
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]
    
  },
  {
    name: 'dPF',
    displayname:'dPF',
    description: 'Displacement Power Factor (Channel)',    
    by:'Ch',
    type:'instantaneous',
    uom:'dPF',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      }
      ,
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [
      {
        name: 'Ch 1',        
        value: '1'
      },
      {
        name: 'Ch 2',        
        value: '2'
      },
      {
        name: 'Ch 3',        
        value: '3'
      },

    ],otherOptions:[]
    
  },
  {
    name: 'DemandkW',
    displayname: 'kW Demand Wattage',
    description: 'Demand Wattage', 
    by:'Elm',
    type:'instantaneous',
    uom:'kW',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]
  },
  {
    name: 'DemandkWPeak',
    displayname:'kW Demand Max',
    description: 'Maximum Demand Wattage',
    by:'Elm', 
    type:'accumulator',
    uom:'kW',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],
    otherOptions:[]
  },
  {
    name: 'V',
    displayname:'Voltage',
    description: 'Volts',
    by:'Ch',
    type:'instantaneous',
    uom:'Volts',
    disabled:true,
    elementOptions: [],
    channelOptions: [],
    otherOptions: [
      {
        name: 'V1 L1-N',        
        value: 'Vin1L1N',
        enabledFor: [3,12,24,48]
      },        
      {
        name: 'V1 L2-N',        
        value: 'Vin1L2N',
        enabledFor: [3,12,24,48]
        
      },
      {
        name: 'V1 L3-N',        
        value: 'Vin1L3N',
        enabledFor: [3,12,24,48]
      },  
      {
        name: 'V2 L1-N',        
        value: 'Vin2L1N',
        enabledFor: [24,48]
      },        
      {
        name: 'V2 L2-N',        
        value: 'Vin2L2N',
        enabledFor: [24,48]
      },
      {
        name: 'V2 L3-N',        
        value: 'Vin2L3N',
        enabledFor: [24,48]
      },
    ]
  },
  {
    name: 'A',
    displayname:'Current',
    description: 'Current', 
    by:'Ch',
    type:'instantaneous',
    uom:'Amps',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [
      {
        name: 'Ch 1',        
        value: '1'
      },
      {
        name: 'Ch 2',        
        value: '2'
      },
      {
        name: 'Ch 3',        
        value: '3'
      },

    ],otherOptions:[]

  },
  {
    name: 'kVAHNet',
    displayname:'kVAh Net',
    description: 'kVAh Net', 
    by:'Elm',
    type:'accumulator',
    uom:'kVAh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]   
  },
  {
    name: 'kVARHNet',
    displayname:'kVARh Net',
    description: 'kVAR Hour Net',
    by:'Elm', 
    type:'accumulator',
    uom:'kVARh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]    
  },
  {
    name: 'kWHNet',
    displayname:'kWh',
    description: 'Watt-Hour',  
    by:'Elm',
    type:'accumulator',
    uom:'kWh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]   
  },
];

export const meterParameterOptions = [    
  {
    name: 'dPF',
    displayname:'dPF',
    description: 'Displacement Power Factor (Element)',    
    by:'Elm',
    type:'instantaneous',
    disabled:true,
    uom:'dPF',
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]
    
  },
  {
    name: 'dPF',
    displayname:'dPF',
    description: 'Displacement Power Factor (Channel)',    
    by:'Ch',
    type:'instantaneous',
    uom:'dPF',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      }
      ,
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [
      {
        name: 'Ch 1',        
        value: '1'
      },
      {
        name: 'Ch 2',        
        value: '2'
      },
      {
        name: 'Ch 3',        
        value: '3'
      },

    ],otherOptions:[]
    
  },
  {
    name: 'DemandkW',
    displayname: 'kW Demand Wattage',
    description: 'Demand Wattage', 
    by:'Elm',
    type:'instantaneous',
    uom:'kW',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]
  },
  {
    name: 'DemandkWPeak',
    displayname:'kW Demand Max',
    description: 'Maximum Demand Wattage',
    by:'Elm', 
    type:'accumulator',
    uom:'kW',
    disabled:true,
    elementOptions: 
      [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],
    otherOptions:[]
  },
  {
    name: 'V',
    displayname:'Voltage',
    description: 'Volts',
    by:'Ch',
    type:'instantaneous',
    uom:'Volts',
    disabled:true,
    elementOptions: [],
    channelOptions: [],
    otherOptions: [
      {
        name: 'L1-N',        
        value: 'Vin1L1N'
      },        
      {
        name: 'L2-N',        
        value: 'Vin1L2N'
      },
      {
        name: 'L3-N',        
        value: 'Vin1L3N'
      },  
    ]
  },
  {
    name: 'A',
    displayname:'Current',
    description: 'Current', 
    by:'Ch',
    type:'instantaneous',
    uom:'Amps',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [
      {
        name: 'Ch 1',        
        value: '1'
      },
      {
        name: 'Ch 2',        
        value: '2'
      },
      {
        name: 'Ch 3',        
        value: '3'
      },

    ],otherOptions:[]

  },
  {
    name: 'kVAHNet',
    displayname:'kVAh Net',
    description: 'kVAh Net', 
    by:'Elm',
    type:'accumulator',
    uom:'kVAh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]   
  },
  {
    name: 'kVARHNet',
    displayname:'kVARh Net',
    description: 'kVAR Hour Net',
    by:'Elm', 
    type:'accumulator',
    uom:'kVARh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]    
  },
  {
    name: 'kWHNet',
    displayname:'kWh',
    description: 'Watt-Hour',  
    by:'Elm',
    type:'accumulator',
    uom:'kWh',
    disabled:true,
    elementOptions: [
      {
        name: 'Element A',        
        value: 'A'
      },
      {
          name: 'Element B',  
          value: 'B'         
      },
      {
          name: 'Element C',    
          value: 'C'     
      },
      {
          name: 'Element D',  
          value: 'D'       
      },
      {
        name: 'Element E',  
        value: 'E'       
      },
      {
        name: 'Element F',  
        value: 'F'       
      },
      {
        name: 'Element G',  
        value: 'G'       
      },
      {
        name: 'Element H',  
        value: 'H'       
      },
      {
        name: 'Element I',  
        value: 'I'       
      },
      {
        name: 'Element J',  
        value: 'J'       
      },
      {
        name: 'Element K',  
        value: 'K'       
      },
      {
        name: 'Element L',  
        value: 'L'       
      },
      {
        name: 'Element M',  
        value: 'M'       
      },
      {
        name: 'Element N',  
        value: 'N'       
      },
      {
        name: 'Element O',  
        value: 'O'       
      },
      {
        name: 'Element P',  
        value: 'P'       
      }
    ],
    channelOptions: [],otherOptions:[]   
  },
];


 export const dataSetTypes = [{
    value:'graph',
    name:'Graphed Values',
    icon: `<img src='/assets/graph-svgrepo-com.svg' alt=graph />` 
  
  },{
    value:'value',
    name:'Accumulated Value',
    icon:`<img src='/assets/number-chart.webp' alt='number-graph' />` 
  }];


export function getParamColor(i){
  var items = [
    '#FFA800',
    '#199504',
    '#AE0E0E',
    '#326CAF',
    '#881484',
    '#190ae7',
    '#079e62',
    '#d0ec4a',
    '#fd6a00',
    '#8f0b0b',
  ]
  return items[i];

}