<template>
    <img src="/assets/dent-logo_white.svg" alt="Dent Instruments" />
</template>

<style>

</style>

<script>
    export default {}
  </script>