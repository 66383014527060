export var devPages = [
  {
    path: '/dev/devtools',
    name: 'devtools',
    component: () => import("@/devtools/development/DevTools.vue"),
  },
  {
    path: '/dev/qc_panel',
    name: 'qc_panel',
    component: () => import("@/devtools/quality_control/QC_Panel.vue"),
  },

]

export var demoPages = [
  // {
  //   // DEMO!!  REMOVE BEFORE DEPLOYMENT!!!
  //   path: '/websocket',
  //   name: 'websocket',
  //   component: () => import("@/devtools/websocket/WebSocket.vue"),
  // },
]