export var noNavPages = [
    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import("@/views/splashpage/Splashpage.vue"),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/auth/login.vue"),
    },
    {
        path: '/forgot',
        name: 'Forgot Password',
        component: () => import("@/views/auth/forgot.vue"),
    },
    {
        path: '/register',
        name: 'Registration',
        component: () => import("@/views/auth/register.vue"),
    },
    {
        path: '/resetpassword',
        name: 'Reset Password',
        component: () => import("@/views/auth/resetpassword.vue"),
    },
    {
        path: '/confirm-registration',
        name: 'Confirm Registration',
        component: () => import("@/views/auth/mfa.vue"),
    },
    {
        path: '/not-found',
        name: '404 not found',
        component: () => import("@/views/404.vue"),
    },   
     
    
]