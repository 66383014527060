<template>
    <div class="pulse-container">
        <div class="pulse white rounded-full"></div>
    </div>
</template>

<style>
.pulse {    
  height: 30px;
  width: 30px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}
.pulse.white {
  background: rgb(255, 196, 0);
  box-shadow: 0 0 0 0 rgba(255, 196, 0, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

</style>