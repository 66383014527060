<template>
<!--- Parameter Selection Dialog -->

  <GDialog v-model="showParamSelector">       
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
          
            <div
              class="
                w-full
                max-w-2xl
                transform
                overflow-hidden
                rounded-lg
                bg-white
                p-6
                text-left
                align-middle
                shadow-xl
                transition-all
              "
            >
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                ADD PARAMETER
              </h3>

              <div class="mt-8">
                <h2 class="text-sm text-slate-500 mb-2">Choose parameter to view

                  <span v-if="dataTypeIsValue" class="italic text-xs">
                  (Additional parameters available for graph type)
                  </span>
                </h2>
                <!-- Paramaters -->                
                <RadioGroup  v-model="parameterOption">  
          <div class="flex flex-wrap">
            
            <RadioGroupOption
              as="template"
              v-for="(param,idx) in parameterOptions"
              :key="param.name" 
              :value="param"

              @click="paramOptionSelected(idx)"
              v-slot="{ active, checked}"
            >
              <div
            
                :class="[
                  active
                    ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                    : '',
                  checked ? 'bg-altblue text-white' : 'bg-[#F1F5F8] ',
                  
                ]"
                class="relative flex cursor-pointer rounded-lg border border-slate-200 px-4 py-2 shadow-sm focus:outline-none mb-4 mr-4 transition hover:bg-altblue/20 focus:bg-altblue/100 focus:text-white active:bg-altblue/70 active:text-white" 
              >
       
                <div class="flex flex-col items-center justify-center text-center">
                    
                      <RadioGroupLabel
                        as="span"
                          :class="[
                            checked ? 'text-white' : 'text-altblue',                        
                            ]"
                        class="font-bold pr-2"
                      >
                        {{ param.name }}
                      </RadioGroupLabel>
                      <RadioGroupDescription
                        as="span"
                        
                        class="inline text-xs"
                      >
                        <span> {{ param.description }}</span>

                      </RadioGroupDescription>
               
                  </div>                 
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>

        <!-- Elements Selector -->        
        <div class="mb-10" v-if="showElementOptions">
            <h2 class="text-sm text-slate-500 mb-2 mt-4">Select Element(s)</h2>
            <Listbox v-model="selectedEl">
            <div class="relative mt-1">
                <ListboxButton
                class="relative w-full cursor-default rounded text-altblue bg-[#F1F5F8] py-2 pt-3 pb-3 pl-3 pr-10 text-left border border-slate-200 shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm transition"
                >
                <span class="block truncate" v-if="selectedEl"> {{ selectedEl.name }}</span>
                <span class="block truncate" v-else> Select Element </span>
                <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <ChevronUpDownIcon
                    class="h-5 w-5 text-altblue"
                    aria-hidden="true"
                    />
                </span>
                </ListboxButton>

                <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                >
                <ListboxOptions
                    class="transition mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="el in selectedParamElements"
                    :key="el.name"
                    :value="el"
                    as="template"
                    >
                    <li
                        :class="[
                        active ? 'bg-altblue text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-10 pr-4',
                        ]"
                    >
                        <span
                        :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                        ]"
                        >{{ el.name }}</span
                        >
                        <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-altblue"
                        >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                    </ListboxOption>
                </ListboxOptions>
                </transition>
            </div>
            </Listbox>
        </div>
        <!-- End Elements Selector -->

        <!-- Channel Selector -->
        <div class="channel-selector" v-if="showChannelOptions">
          <h2 class="text-sm text-slate-500 mb-2 mt-4">Select Channel(s)</h2>
        <div class="text-sm mb-4 hidden">            
            <span class="pr-6"><button class="text-textlink underline" @click="selectAllChannels">select all</button></span>
            <span><button class="text-textlink underline" @click="selectNoneChannels">select none</button></span>
        </div>
        <div class="flex space-x-4 text-sm">
          <div class="mt-1"           
              v-for="(channel,idx) in selectedParamChannels"
              :key="idx"                            
          >

            <Checkbox :value="channel" v-model="meterParameterOptionChannel" />
          
          </div>  
                    
        </div>
      </div>
        <!-- End Channel Selector -->

        <!-- Option Selector -->        
        <div class="mb-10" v-if="showOtherOptions">
            <h2 class="text-sm text-slate-500 mb-2 mt-4">Select Option(s)</h2>
            <div class="flex space-x-4 text-sm">
              <div class="mt-1"           
                v-for="(otherOption,idx) in selectedParamOtherOptions"
                :key="idx"                            
              >
                <Checkbox :value="otherOption" v-model="meterParameterOptionOtherOption" />
              </div>
          </div>
             
        </div>
        <!-- End Elements Selector -->

                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-primary
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    hover:bg-primary/70
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                    mr-4
                    mt-8
                    disabled:opacity-50
                    disabled:bg-primary/70
                  "
                  @click="saveParam"
                  :disabled="!canAddParam"
                >                
                  Confirm
                </button>

                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-blue-100
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-blue-900
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="closeParamModal"
                >
                  Cancel
                </button>
              </div>
            </div>                      
        </div>
      </div>
    </GDialog>
  
<!---  End Parameter Selection Dialog -->

  <div class="widget-form rounded-md p-6 w-full max-w-[800px]">
  
    <label class="block uppercase text-slate-400 font-semibold text-sm pb-1"
      >Type <span class="text-[8px] text-alert align-super lowercase  ">* required</span></label>

      <RadioGroup v-model="dataSetType" class="flex flex-wrap">                
          <RadioGroupOption            
            as="template"
            v-for="(setType,index) in dataSetTypes"
            :key="setType.name"
            :value="setType"
            @click="dataTypeChange(index)"
            v-slot="{ active, checked }">
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-sky-50/90'
                  : '',
                checked ? 'bg-gradient-to-r from-primary/20 to-primary/20' : 'bg-white',
              ]"
              class="select-none min-w-[200px] relative cursor-pointer rounded-lg border border-slate-200 text-sm p-0 px-6 py-6 shadow-sm focus:outline-none mb-4 mr-4 transition"
            >
              
                <div class="flex flex-col justify-center items-center">
                    <div class="mb-2 w-[48px]">
                    <span v-html="setType.icon" ></span>
                    </div>
                    <RadioGroupLabel
                      as="p"                      
                      class="font-medium "
                    >                                                              
                      {{ setType.name }}
                      
                    </RadioGroupLabel>                  
                </div>                              
            </div>
          </RadioGroupOption>        
      </RadioGroup>

    <label class="block uppercase text-slate-400 font-semibold text-sm pb-1">Set time interval <button type="button" @click="openTimeHelpModal" class="">
      <QuestionMarkCircleIcon class="h-6 w-6 inline" />
    </button><span class="text-[8px] text-alert align-super lowercase  ">* required</span></label>

      <div v-if="dataTypeIsGraph">

          <div v-if="!showCustomTimeIntervals">

            <TimeFrameSelector v-model="timeframe" class="mb-2" @changed="timeselectorChanged" :dataType="dataSetType.value" />
            <div class="italic text-xs mb-4">-- or --</div>
            <button @click="toggleCustom" class="transition bg-slate-700/40 text-white px-6 py-2 hover:bg-slate-700/80 rounded text-sm mb-6">Set custom time intervals</button>
          </div>

          <div v-if="showCustomTimeIntervals" class="mb-6">
            <div class="flex">
              <div class="datepicker-wrap bg-slate-400/20 rounded p-2 mr-2">
                <label class="block text-slate-600 font-bold text-sm pb-1">Start Date/time</label>
                <Datepicker v-model="timeintervaldatestart" time-picker-inline :is24="false" inputClassName="text-xs" @changed="timeselectorChanged" class="mr-4 text-xs"></Datepicker>
              </div>
              <div class="datepicker-wrap bg-slate-400/20 rounded p-2 mr-2">
                <label class="block text-slate-600 font-bold text-sm pb-1">End Date/time</label>
              <Datepicker v-model="timeintervaldateend" time-picker-inline :is24="false" @changed="timeselectorChanged" class="mr-4 text-xs"></Datepicker>
            </div>
            </div>
            <button @click="toggleCustom" class="block clear bg-transparent py-2 text-slate-900 text-sm mb-6">reset</button>
          </div>

    </div>
    <div v-else-if="dataTypeIsValue">
      <div>
        <RadioGroup v-model="specificTimeInterval">
        <div class="flex flex-wrap mb-4 font-bold uppercase">
          <RadioGroupOption
            as="template"
                      
           value="current"
           @click="valueTimeIntervalChange(index)"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  : '',
                checked ? 'bg-primary text-white ' : 'bg-white',
              ]"
              class="relative flex cursor-pointer rounded-l-md border border-slate-200 text-sm p-4 shadow-sm focus:outline-none transition"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div>
                    <RadioGroupLabel
                      as="span"
                      :class="checked ? 'text-white' : 'text-slate-500'"                 
                    >
                      Latest Value
                    </RadioGroupLabel>
                  </div>
                </div>
              </div>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
                      
           value="lastmonth"
           @click="valueTimeIntervalChange(index)"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  : '',
                checked ? 'bg-primary text-white ' : 'bg-white',
              ]"
              class="relative flex cursor-pointer  border border-slate-200 text-sm p-4 shadow-sm focus:outline-none transition"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div>
                    <RadioGroupLabel
                      as="span"
                      :class="checked ? 'text-white' : 'text-slate-500'"                 
                    >
                      Last Month
                    </RadioGroupLabel>
                  </div>
                </div>
              </div>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
                      
           value="currentmonth"
           @click="valueTimeIntervalChange(index)"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  : '',
                checked ? 'bg-primary text-white ' : 'bg-white',
              ]"
              class="relative flex cursor-pointer rounded-r-md border border-slate-200 text-sm p-4 shadow-sm focus:outline-none transition"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div>
                    <RadioGroupLabel
                      as="span"
                      :class="checked ? 'text-white' : 'text-slate-500'"                 
                    >
                      Current Month
                    </RadioGroupLabel>
                  </div>
                </div>
              </div>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
                      
           value="specifictime"
           @click="valueTimeIntervalChange(index)"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  : '',
                checked ? 'bg-primary text-white ' : 'bg-white',
              ]"
              class="relative flex cursor-pointer ml-2 rounded-l-md rounded-r-md border border-slate-200 text-sm p-4 shadow-sm focus:outline-none  transition"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div>
                    <RadioGroupLabel
                      as="span"
                      :class="checked ? 'text-white' : 'text-slate-500'"                 
                    >
                      Custom Range
                    </RadioGroupLabel>

                  </div>
                </div>
              
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>        
      </div>
      
      <div v-if="showCustomSpecificTime" class="mb-6">
        <span class="text-xs">Calculated range will return the difference in value between the two selected date/times.</span>
        <div class="flex mt-1">
          <div class="datepicker-wrap p-2 mr-2 bg-slate-400/20 rounded">
            <label class="block text-slate-600 font-bold text-sm pb-1">Start Date/time</label>
            <Datepicker v-model="timeintervaldatestart" time-picker-inline :is24="false" inputClassName="text-xs" class="mr-4 text-xs"></Datepicker>
          </div>
          <div class="datepicker-wrap p-2 mr-2 bg-slate-400/20 rounded">
            <label class="block text-slate-600 font-bold text-sm pb-1">End Date/time</label>
            <Datepicker v-model="timeintervaldateend" time-picker-inline :is24="false" inputClassName="text-xs" class="mr-4 text-xs"></Datepicker>
          </div>
        </div>
      </div>      
    </div>
    
    <div v-else class="text-xs text-slate-500 pb-4 italic">Please first select a data widget type</div>
    <label class="block uppercase text-slate-400 font-semibold text-sm pb-1">Parameters (Max {{maxParams}}) <span class="text-[8px] text-alert align-super lowercase  ">* required</span></label>
    <div v-if="dataTypeIsGraph || dataTypeIsValue" class="mb-4">
    <div class="added-params flex flex-wrap mb-4 text-sm">
          <div v-for="(param,index) in selectedParams" :key="param.param" class="added-param relative border border-slate-400 bg-white shadow-md rounded p-4 mr-4 mb-2">
              
              <span class="param-name mr-4">
                <span class="text-sm font-bold"></span>{{param.displayname}}
              </span>
              <span class="remove-param cursor-pointer absolute right-0 top-0 p-1" @click="selectedParams.splice(index,1);"><XMarkIcon class="h-6 w-5 text-slate-400 float-right"/></span>
          </div>
      </div>
    
    <button v-if="allowMoreParams"
      @click="openParamModal"
      class="
        border-dashed border
        px-8
        py-3
        rounded
        bg-none
        uppercase
        text-slate-400
        font-bold
        text-sm"

    >
      <PlusIcon class="h-6 w-6 text-slate-400 inline" /> Add Parameter
    </button>
  </div>
      <div v-else class="text-xs text-slate-500 pb-4 italic">Please first select a data widget type</div>
      
      <Input
      type="text"
      id="name"
      labelText="Name"
      placeHolder="Name your data widget"
      class="mb-4 text-slate-500 text-sm"
      v-model="widgetName"
      isrequired
    />

    <label
      class="
        block
        uppercase
        text-slate-400
        font-semibold
        text-sm
        float-left
        pb-1
      "
      >Comment/Note</label>
    <textarea
      class="
        rounded-md
        box-border
        w-full
        bg-white
        border border-slate-200
        p-2
        placeholder:text-xs placeholder:text-slate-300
        focus:border-slate-500
        focus-visible:border-slate-500
        active:border-slate-500
        focus:outline-none
        mb-4 text-slate-500 text-sm
      "
      rows="2"
      placeholder="Describe your data widget"
      id="comment"
      v-model="comment"
    ></textarea>
    <div class="mt-2">
      <div class="text-alert text-left text-xs mb-2" v-if="showCreateError"><ExclamationTriangleIcon class="inline h-6 w-6"/> There was a problem.</div>
      <div class="text-alert text-left text-xs mb-2" v-if="showMaxParamsError"><ExclamationTriangleIcon class="inline h-6 w-6"/> A maximum of 6 parameters are supported.</div>
      <Button class="mr-4 bg-primary shadow-md px-12 py-3 disabled:opacity-50" @click="putWidget" :disabled="!canSubmit">
        <span v-if="showLoading" class="loading">Loading <span class="btn-loader"></span></span>
        <span v-else>{{confirmationBtnText}}</span>    
      </Button>
    </div>
        
  </div>


  <GDialog v-model="isTimeHelpOpen1">

<div class="fixed inset-0 overflow-y-auto">
  <div
    class="flex min-h-full items-center justify-center p-4 text-center"
  >

      <div
        class="
          w-full
          max-w-md
          transform
          overflow-hidden
          rounded
          bg-white
          p-8
          text-left
          align-middle
          shadow-xl
          transition-all
        "
      >
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Time Interval
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            
          </p>
        </div>

        <div class="mt-4">
          <button
            type="button"
            class="
              inline-flex
              justify-center
              rounded-md
              border border-transparent
              bg-blue-100
              px-4
              py-2
              text-sm
              font-medium
              text-blue-900
              hover:bg-blue-200
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-blue-500
              focus-visible:ring-offset-2
            "
            @click="closeTimeHelpModal"
          >
            Close
          </button>
        </div>
      </div>

  </div>
</div>
</GDialog>


  
</template>
  
    
<script setup>
import { ref, watch, computed,watchEffect,toRaw,onMounted } from 'vue' 
import { useStore } from 'vuex' 
import TimeFrameSelector from '@/components/dashboard/vtimeframeselector.vue';
import Checkbox from '@/components/elements/vcheckbox.vue';
import { meterParameterOptions_v2,dataSetTypes } from '@/views/dashboards/dashboardConfig.js';
import { PlusIcon, CheckIcon, ChevronUpDownIcon, XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/20/solid'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import * as sh from '@/scripts/stringhelpers';
import * as datajs from "@/views/meters/data.js";

const emit = defineEmits(['addWidget','updateWidget']);

const props = defineProps({
   Settings: Array
 });



import {

  RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
} from '@headlessui/vue'


const store = useStore();
const allowMoreParams = ref(true);
const showParamSelector = ref(false)
const canSubmit = ref(false)
const showLoading = ref(false)
const showElementOptions = ref(false);
const showChannelOptions = ref(false);
const showOtherOptions = ref(false);
const showCustomTimeIntervals = ref(false);
const dataTypeIsGraph = ref(false);
const dataTypeIsValue = ref(false);
const widgetName = ref('');
const comment = ref('');
const timeframe = ref(null);
const validTimeFrame = computed(()=>{ return validateTimeInterval() });
const selectedParams = ref([]);
const selectedParamsCount = computed(() => {return selectedParams.value.length});
const selectedParamElements = ref([]);
const selectedParamChannels = ref([]);
const selectedParamOtherOptions = ref([]);
const specificTimeInterval = ref(null);
const canAddParam = ref(false);
const allowMultipleChannels = ref(true);
const showMaxParamsError = ref(false);
const showCreateError = ref(false);
const isTimeHelpOpen = ref(false);

const timeintervaldateend = ref();
const timeintervaldatestart = ref();
const timeintervalsingledate = ref();
const meter = computed(() => {return store.getters.meter});
const userName = computed(() => {return store.state.loggedinEmail.toLowerCase()});
const maxParams = ref(6);

const elCnt = computed(() => {
  return datajs.getElementCount(meter.value.name)
});

const parameterOption = ref();

const selectedEl = ref(null);
const parameterOptions = ref(meterParameterOptions_v2);

const meterParameterOptionChannel = ref([]);
const meterParameterOptionChannelSelected = computed(() => {return meterParameterOptionChannel.value.length});

const meterParameterOptionOtherOption = ref([]);
const meterParameterOptionOtherOptionSelected = computed(() => {return meterParameterOptionOtherOption.value.length});

const widgetId = ref('');
const showCustomSpecificTime = ref(false);
const dataSetType = ref(null);
const confirmationBtnText = ref('Save');
const isEdit = ref(false);
//set values if edit mode
if(props.Settings.id)
{  
  widgetId.value = props.Settings.id;
  isEdit.value = true;
  //update button text to update
  confirmationBtnText.value = "Update";

  if(props.Settings.settings.FullName)
    widgetName.value = props.Settings.settings.FullName;

  if(props.Settings.settings.Comment)
    comment.value = props.Settings.settings.Comment;

  if(props.Settings.settings.Type){
    dataSetType.value = dataSetTypes.find((e) => e.value == props.Settings.settings.Type);
    dataTypeChange();
  }

  //make time interval visible
  if(props.Settings.settings.CustomTimeIntervalStart)
  {
    toggleCustom();
    
    //set custom 
    timeintervaldateend.value = props.Settings.settings.CustomTimeIntervalEnd;
    timeintervaldatestart.value = props.Settings.settings.CustomTimeIntervalStart;

  }
  else{
    //set time interval
    timeframe.value = props.Settings.settings.TimeInterval;
    specificTimeInterval.value = props.Settings.settings.TimeInterval
  }

  //make parameters visible
  if(props.Settings.settings.Parameters)
    selectedParams.value = props.Settings.settings.Parameters;


    canSubmit.value = true;

}

function closeParamModal() {
  parameterOption.value = null;
  showParamSelector.value = false;
}
function openParamModal() {
  showParamSelector.value = true;
  resetParamSelectionOptions();
}

function closeTimeHelpModal() {
  isTimeHelpOpen.value = false
}
function openTimeHelpModal() {
  isTimeHelpOpen.value = true
}


function toggleCustom(){    
  timeframe.value = null;
  timeintervaldatestart.value = null;
  timeintervaldateend.value = null;
  showCustomTimeIntervals.value = !showCustomTimeIntervals.value;
  specificTimeInterval.value = 'specifictime';
  showCustomSpecificTime.value = true;
}
function resetDataViewOptions(){

    widgetName.value = '';
    comment.value = '';
    selectedParams.value = [];
    specificTimeInterval.value = null;
    timeframe.value = null;
    dataSetType.value = null;
    timeintervaldateend.value = null;
    timeintervaldatestart.value = null;
    timeintervalsingledate.value = null;
    showLoading.value = false;
    dataTypeIsGraph.value = false;
    dataTypeIsValue.value = false;
}

function resetParamSelectionOptions(){

    showElementOptions.value = false;
    showChannelOptions.value = false;
    showOtherOptions.value = false;

    
    selectedParamElements.value = [];
    selectedParamChannels.value = [];
    selectedParamOtherOptions.value = [];
    
    meterParameterOptionChannel.value = [];
    meterParameterOptionOtherOption.value = [];

    selectedEl.value = null;
}

function paramOptionSelected(i, event){     
  
  //reset element and channel
  resetParamSelectionOptions();
    
    
  if(parameterOption.value.elementOptions.length>0) 
    loadElementOptions(parameterOption.value.elementOptions.slice(0,elCnt.value));
  
  if(parameterOption.value.channelOptions.length>0) {
    loadChannelOptions(parameterOption.value.channelOptions);
    if(!parameterOption.value.multipleChannels)
      allowMultipleChannels.value = false;
  }

  if(parameterOption.value.otherOptions.length>0)
  {
    
    loadOtherOptions(parameterOption.value.otherOptions)
  }
              
}

function loadElementOptions(elementOpts){
  
  let elsToLoad = [];
  
  for (let i = 0; i < elCnt.value; i++) {
    elsToLoad.push(elementOpts[i]);       
  }
  

  selectedParamElements.value = elsToLoad;  
  showElementOptions.value = true;
  
}
function loadChannelOptions(channelOptions){
  
  selectedParamChannels.value = channelOptions;  
  showChannelOptions.value = true;

}

function loadOtherOptions(otherOptions){
  
  //get meter type
  let mType = datajs.getMeterType(meter.value.name);

    //load options that are enabled for meter 

  let optsToLoad = [];

  for(let o of otherOptions)
  {
    if(o.enabledFor.includes(mType))
    {
      optsToLoad.push(o);
    }
  }

  selectedParamOtherOptions.value = optsToLoad;  
  showOtherOptions.value = true;

}
function dataTypeChange(){  

  //reset param selection
  resetParamSelectionOptions();

  selectedParams.value = [];
  timeframe.value = null;
  specificTimeInterval.value = null;
  timeintervalsingledate.value = null;
  showCustomSpecificTime.value = false;

  dataTypeIsGraph.value = false;
  dataTypeIsValue.value = false;

  //reset timeselections

  if(dataSetType.value.value === "graph")
  {
    dataTypeIsGraph.value = true;
    maxParams.value = 6;

    parameterOptions.value = meterParameterOptions_v2;

  }
  else if(dataSetType.value.value === "value")
  {    
    dataTypeIsValue.value = true;
    maxParams.value = 1;
    
    parameterOptions.value = meterParameterOptions_v2.filter(p => p.type == "accumulator")
 
  }

}

function valueTimeIntervalChange(){

  if(specificTimeInterval.value == "specifictime")
  {
    //show specific time
    showCustomSpecificTime.value = true;

  } else {

    showCustomSpecificTime.value = false;
  }

}

function createSelectedParam(thisParameterOption, thisElement, thisChannel, thisOther){  

  let pBy = thisParameterOption.by;

  if(pBy == null)
    pBy = 'Elm';

  let thisParam = {};
  //will need to create a param for each channel
  
  let paramDisplayname = thisParameterOption.displayname; 
  let paramStr = thisParameterOption.name + "/" + pBy;

  if(thisElement != null)
  {
    paramStr += "/" + thisElement.value;
    paramDisplayname += "/" + thisElement.name;
  }

  if(thisChannel != null)
  {
    paramStr += thisChannel.value.toString();
    paramDisplayname += "/" + thisChannel.name;
  }

  if(thisOther != null)
  {
    paramStr += "/" + thisOther.value;
    paramDisplayname += "/" + thisOther.name;
  }

  thisParam.param = paramStr;
  thisParam.displayname = paramDisplayname;
  thisParam.uom = thisParameterOption.uom;

  return thisParam;
}


function objectExists(array, obj) {
    for (let i = 0; i < array.length; i++) {
        if (isEqual(array[i], obj)) {
            return true;
        }
    }
    return false;
}

// Helper function to check if two objects are equal
function isEqual(obj1, obj2) {
    // Implement your equality check logic here
    // For example, compare specific properties
    return obj1.param === obj2.param;
}


function saveParam(){    

  let createdParams = [];
  //if otheroptions selected, no element and channel
  if(meterParameterOptionOtherOption.value.length > 0)
  {
    for(let i=0;i<meterParameterOptionOtherOption.value.length;i++)
    {            
      createdParams.push(createSelectedParam(parameterOption.value, null, null, meterParameterOptionOtherOption.value[i]));
    }
  }
  else{
    //other options not selected
    if(meterParameterOptionChannel.value.length > 0)
    {
      for(let i=0;i<meterParameterOptionChannel.value.length;i++)
      {            
        createdParams.push(createSelectedParam(parameterOption.value, selectedEl.value, meterParameterOptionChannel.value[i], null));
      }
    } 
    else{

      //no channels///
      createdParams.push(createSelectedParam(parameterOption.value, selectedEl.value, null, null));

    }  
  }

  for(let p of createdParams)
  {

    if(!objectExists(selectedParams.value, p))
    {
      selectedParams.value.push(p);
    }
  }

  closeParamModal();
  
  parameterOption.value = null;
  selectedEl.value = null;
  meterParameterOptionChannel.value = [];
  meterParameterOptionOtherOption.value = [];  
  
}

watchEffect(() => {    
  
  if(selectedParams.value.length == maxParams.value)
  {
      allowMoreParams.value = false;    
      showMaxParamsError.value = false; 
  }
  else if(selectedParams.value.length > maxParams.value)
  {
    showMaxParamsError.value = true;    
    allowMoreParams.value = false; 
  }
  else
    allowMoreParams.value = true;    
  }
)

watch([selectedParamsCount, widgetName, validTimeFrame, dataSetType],() => {

    canSubmit.value = false;
    
  if(selectedParamsCount.value > 0 && selectedParamsCount.value <= 6 && widgetName.value.length > 0 && dataSetType.value.value.length > 0 && validTimeFrame.value)
    canSubmit.value = true;
  

})

watchEffect (() => {

  canAddParam.value = false; 

  if(parameterOption.value != null){

    canAddParam.value = true;
    
    if(showOtherOptions.value){
      canAddParam.value = (meterParameterOptionOtherOptionSelected.value > 0);
    }

    if(showChannelOptions.value){
      canAddParam.value = (meterParameterOptionChannelSelected.value > 0);
    }

    if(showElementOptions.value){    
      canAddParam.value = (selectedEl.value != null);
    }                  
  }
  
})

function timeselectorChanged(){
  
  //calculate the time interval
  validateTimeInterval();
  
}
function getTimeFrameValue(){

  if(!dataSetType.value)
    return null;

  if(dataSetType.value.value == 'graph')
  {
    return timeframe.value;
  }
  else if(dataSetType.value.value == 'value')
  {
    if(specificTimeInterval.value !== "specifictime")
    {
      return specificTimeInterval.value;
    }
    else
      return null
  }
  else
      return null;
}

function getTimeIntervalStart(){

  //case when type is graph
  if(!dataSetType.value)
    return null;

  if(dataSetType.value.value == 'graph')
  {        
    //get starttime for graph type
    if(showCustomTimeIntervals.value)
    {
      //specific datetime entered        
      if(timeintervaldatestart.value){        
        return timeintervaldatestart.value;    
      }
      else
        return null;          
    }
    else{      
      return null;       
    }

  }
  else if(dataSetType.value.value == 'value')
  {

    if(specificTimeInterval.value == "specifictime")
    {
      //specific datetime entered
      if(timeintervaldatestart.value)
        return timeintervaldatestart.value;
      else
        return null;          
    }
    else
      return null;
        
  }
  else
      return null;
  
}
function getTimeIntervalEnd() {

  if (!dataSetType.value) {
    return null;
  }

  if (dataSetType.value.value === 'graph') {
    if (showCustomTimeIntervals.value && timeintervaldateend.value) {
      return timeintervaldateend.value;
    }
  } else if (dataSetType.value.value === 'value') {
    if (specificTimeInterval.value === 'specifictime' && timeintervaldateend.value) {
      return timeintervaldateend.value;
    }
  }

  return null;
}


function validateTimeInterval(){  

  
  if(getTimeFrameValue() || (getTimeIntervalStart() && getTimeIntervalEnd()))
    return true;
  else
    return false;

}

async function putWidget(){


  showLoading.value = true;
  showCreateError.value = false;

  try{          

      var settingsObj = {};

      settingsObj["Parameters"] = toRaw(selectedParams.value);
      settingsObj["Comment"] = comment.value;
      settingsObj["FullName"] = widgetName.value;
      settingsObj["CreatedDate"] = new Date().toISOString();
      settingsObj["Type"] = dataSetType.value.value;
      settingsObj["TimeInterval"] = getTimeFrameValue();
      settingsObj["CustomTimeIntervalStart"] = getTimeIntervalStart();
      settingsObj["CustomTimeIntervalEnd"] = getTimeIntervalEnd();

      var params = {
        // Add and/or Update a widget
        request: "putWidget",        
        username: userName.value,
        metername: meter.value.name,
        settings: settingsObj
      };

      if(widgetId.value){
        params.widgetId = widgetId.value;
      }

      const putResponse = await store.dispatch("dentcloud_API", params);

      if(putResponse.success)
      {

          //reset 
          resetDataViewOptions();
          resetParamSelectionOptions();

          let emitVal = 'addWidget';

          if(isEdit.value)
            emitVal = 'updateWidget';
          else{
            //add widget id to params
            params.widgetId = putResponse.widgetId;
          }
          
          emit(emitVal,params); 



        } else {
          showCreateError.value = true;
          showLoading.value = false;
        }

  }
  catch(e)
  {
    console.log(e);
    showCreateError.value = true;
    showLoading.value = false;    
  }
  finally{}


}

function selectNoneChannels(){


}

async function reLoadMeterWidgets()
{


}

</script>

  
  <style>
</style>