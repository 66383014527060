<!--
    AUTHOR: Ryan Lang
    LATEST UPDATED DATE: 12/7/2022
    DESCRIPTION: Parameter selection component. as of 12/5 not used in a page view
    ADDITIONAL INFORMATION: Originally intended to be used as componentized parameter selector. 
-->
<template>
    <div class="time-selector flex text-xs lg:text-sm"  v-if="dataType == 'graph'">
        <div>
            <input type="radio" id="last1" name="timeselector" v-model="modelVal" value="-1"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            
            <label for="last1">
            <span class="md:leading-none">Last 1 HR</span>
            </label>
        </div>  
        <div>
            <input type="radio" id="last3" name="timeselector" v-model="modelVal" value="-3"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last3">
                <span>Last 3 HRS</span>
            </label>
        </div>
    
        <div>
            <input type="radio" id="last6" name="timeselector" v-model="modelVal" value="-6"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last6">
                <span>Last 6 HRS</span>
            </label>
        </div>

        <div>
            <input type="radio" id="last12" name="timeselector" v-model="modelVal" value="-12"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last12">
                <span>Last 12 HRS</span>
            </label>
        </div>
        <div>
            <input type="radio" id="last24" name="timeselector" v-model="modelVal" value="-24"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last24">
                <span>Last 24 HRS</span>
            </label>    
        </div>
    </div>
    <div class="time-selector flex" v-else-if="dataType == 'value'">
        <div>
            <input type="radio" id="current" name="timeselector" v-model="modelVal" value="current"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="current">
                <span>Current</span>
            </label>    
        </div>
        <div>
            <input type="radio" id="lastmonth" name="timeselector" v-model="modelVal" value="lastmonth"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="lastmonth">
                <span>Last Month</span>
            </label>    
        </div>
        <div>
            <input type="radio" id="lastyear" name="timeselector" v-model="modelVal" value="lastyear"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="lastyear">
                <span>Last Year</span>
            </label>    
        </div>
    </div>
    <div class="time-selector flex" v-else>
        <div>
            <input type="radio" id="last12" name="timeselector" v-model="modelVal" value="-12"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last12">
                <span>Last 12 HRS</span>
            </label>
        </div>
        <div>
            <input type="radio" id="last24" name="timeselector" v-model="modelVal" value="-24"
            @input="$emit('update:modelValue', $event.target.value)" @change="$emit('changed', $event.target.value)" />
            <label for="last24">
                <span>Last 24 HRS</span>
            </label>    
        </div>
    </div>

</template>

<script setup>
import { ref, computed } from "vue";

const modelVal = ref(null);

const props = defineProps({
   id: String,
   modelValue: String,
   dataType: String
 });

 modelVal.value = props.modelValue;


</script>

<style>

.time-selector input[type=radio] {
  display: none;
}

.time-selector input[type=radio] + label span {  
    display: block;
  height: 54px;  
  cursor: pointer;    
     
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding:0px 18px;
  color: rgb(100 116 139);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 54px;

  border-right:1px solid #c4c4c4;
  border-left:1px solid #ECECEC;
  border-top:1px solid #E9E9E9;
  border-bottom:1px solid #E9E9E9;
    
}

@media screen and (max-width: 1024px)
{
    .time-selector input[type=radio] + label span {  

        height: auto;   
        padding:10px 10px;
        line-height:normal;
        
    }
}


@media screen and (max-width: 500px)
{
    .time-selector input[type=radio] + label span {  

        min-height: 75px;
    }
}

.time-selector input[type=radio]:checked + label span{

    background-color: #062744;
    color:#fff;
    border-right:1px solid #062744;
    border-left:1px solid #062744;
    border-top:1px solid #062744;
    border-bottom:1px solid #062744;
}
.time-selector div:first-child > label span
{
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    border-left:2px solid #E9E9E9;
}
.time-selector div:last-child > label span
{
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
    border-right:2px solid #E9E9E9;
}
.time-selector div:first-child input[type=radio]:checked + label span{
    border-left:2px solid #062744;
}
.time-selector div:last-child input[type=radio]:checked + label span{
    border-right:2px solid #062744;
}

</style>