<template>
    <v-chart class="chart" ref="chart" :option="ChartOptions" autoresize />
  </template>
  
  <script setup>
  import { use } from 'echarts/core';
  import { SVGRenderer,CanvasRenderer } from 'echarts/renderers';
  import { LineChart } from 'echarts/charts';
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
GridComponent,  ToolboxComponent 
  } from 'echarts/components';
  import VChart, { THEME_KEY } from 'vue-echarts';
  import { ref, provide } from 'vue';

  use([
  CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,ToolboxComponent 
  ]);
  
  provide(THEME_KEY, 'light');

   //component property definitions
 const props = defineProps({  
   ChartOptions: Object,
 });

 const chart = ref(null);


       

  </script>
  
  <style scoped>
  .chart {
    height: 100%;
  }
  </style>
  