<template>
    <div class="input-container relative transition">

    <label :for="id" class="uppercase text-slate-400 font-semibold text-sm float-left pb-1 select-none">{{ labelText }} 
      <span v-if="isrequired" class="text-[8px] text-alert align-super lowercase  ">* required</span>
      
    </label>
    
    <router-link v-if="type=='password' && !hidehelperlink" to="/forgot" class="text-right text-xs text-slate-400 float-right select-none" tabindex="-1">Forgot password?</router-link>  

    <i v-if="type == 'password'" class="eye absolute right-2 top-9 cursor-pointer select-none" id="eye" tabindex="-1" @click="showPassword = !showPassword">
       
      <EyeIcon v-if="showPassword" class="h-4 w-4 text-slate-400"  />
      <EyeSlashIcon v-else class="h-4 w-4 text-slate-400"/>

    </i>
                  
    <input v-if="type == 'password'" class="rounded-md box-border w-full bg-white border border-slate-200 p-2 placeholder:text-xs placeholder:text-slate-300 focus:border-slate-500 focus-visible:border-slate-500 active:border-slate-500 focus:outline-none transition" v-bind="$attrs"   
        :class="addedclasses"     
        :type="passwordFieldType"    
        :placeholder="placeHolder"        
        :id="id"  
        :maxlength = "maxchars"     
        :tabindex="tabindex"   
        @input="$emit('update:modelValue', $event.target.value)"
        oninput="javascript: if (this.maxLength > 0 && this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  
              
    />

    <input v-else class="rounded-md box-border w-full bg-white border border-slate-200 p-2 placeholder:text-xs placeholder:text-slate-300 focus:border-slate-500 focus-visible:border-slate-500 active:border-slate-500 focus:outline-none transition" v-bind="$attrs"   
        :class="addedclasses"     
        :type="type"    
        :placeholder="placeHolder"        
        :id="id"  
        :maxlength="maxchars"
        :tabindex="tabindex"   
        :value="modelValue"              
        @input="$emit('update:modelValue', $event.target.value)"
        oninput="javascript: if (this.maxLength > 0 && this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  
              
    />
    

    </div>

  </template>
  
  <style>

  </style>
  
  <script setup>

import { computed, ref } from "vue";
import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/20/solid'
    
const showPassword = ref(true);
const passwordFieldType = computed(() => showPassword.value ? "password" : "text");

const props = defineProps({
  type: String,
  id: String,
  labelText: String,    
  placeHolder: String,
  hidehelperlink: Boolean,
  isrequired: Boolean,
  maxchars: String, 
  addedclasses:String,
  modelValue: String,
  tabindex: {
      type: Number,
      required: false,
      default: 0
  }
});


</script>