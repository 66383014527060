<template>
  <div
    :class="chevron"
    style="position:fixed;z-index: 20; top: 50px"
    :style="{
      left: navWidth + 'px',
      'transition-duration': transitionSpeed + 's',
      transform: 'rotate(' + (isExpanded ? 0 : 180) + 'deg)',
    }"
    @click="togglenav"
  >
    <ChevronLeftIcon class="h-8 w-8 mx-auto text-slate-500" />
  </div>
  <div
  class="flex flex-col justify-between"
    :class="leftNav"
    :style="{
      width: navWidth + 'px',
      'transition':'width ' + transitionSpeed + 's'
    }"
  >
  <div class="mainnav">
    <!-- Navigation Routes -->
    <ul :class="navTabs" v-for="tab in nav" :key="tab.name">
      
        <li
          class="noselect transition"
          :class="selectedClass(tab)"
        >
          <router-link :to="'redirect' in tab ? tab.redirect : tab.path" class="nav-parent hvr-underline-from-left">
            <component :is="tab.icon" class="nav-icon h-6 w-6" />
            <span class="nav-text">{{ tab.name }}</span>
          </router-link>
        
          <!-- Router Children -->
          <ul
            class="nav-children "
            :style="{
              'max-height': isExpanded && tab.childDrop ? '33vh' : '0vh',
            }"
          >
            <li
              v-for="child in getTabChildren(tab)"
              :key="child.name"
              class="noselect hvr-underline-from-left hvr-bg-white"
              :class="selectedClassChild(child)"
            >
              <router-link
                :to="'redirect' in child ? child.redirect : child.path" class=""
              >
                <div
                  class="nav-child-spacer"
                  style="transition-duration: 0.4s"
                  :style="{
                    'margin-left': isExpanded && tab.childDrop ? '0px' : '50px',
                  }"
                ></div>
                <!-- <component :is="tab.icon" class="nav-icon h-6 w-6" /> -->
                <span class="nav-child-text">{{ child.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      
    </ul>
    </div>
    <div class="bottom-nav-links">
      <ul class="">
      
      <li v-for="tab in bottomNav" :key="tab.name"
        class="noselect transition text-white navItem font-bold">
        <router-link :to="'redirect' in tab ? tab.redirect : tab.path" class="">
          <component :is="tab.icon" class="nav-icon h-5 w-5" />
          <span class="nav-text">{{ tab.name }}</span>
        </router-link>
      </li>
      <!-- <li class="noselect transition text-white navItem font-bold"><a class="" @click="logOut"><ArrowRightOnRectangleIcon class="nav-icon h-5 w-5" /><span class="nav-text">Log Out</span></a></li> -->
                
  </ul>
    <div class="bottom-attr bg-secondary text-white text-xs p-2">{{compName}}</div>
  </div>
  </div>
</template>

<script setup>
  import { ref, computed, provide,onMounted} from "vue";
  import { useStore } from 'vuex' ;
 import { useRoute } from "vue-router";
  import { LifebuoyIcon,AdjustmentsVerticalIcon,ChevronLeftIcon,PlusIcon,ArrowRightOnRectangleIcon } from "@heroicons/vue/20/solid";
  import { withNavPages,bottomNavigationPages } from "@/framework/routes/withNav.js";
  import { getCurrentInstance } from 'vue';
  const store = useStore();
  const route = useRoute();
  const compName = getCurrentInstance().appContext.config.globalProperties.CompanyName;
  const emit = defineEmits(['emit_toggleNav']);

  const props = defineProps({  
    navWidth: { type:Number, default:0},
    isExpanded: { type:Boolean, default:false},
    transitionSpeed: { type:Number, default:0},
    navState: { type:Object, default:{}}
  });



  const nav = ref(withNavPages);
  const bottomNav = ref(bottomNavigationPages);
  const isExpandedVal = ref(props.isExpanded);
  const expandedClass = ref("");
  const leftNav = ref();
  const leftNavDefaultClasses = ref("leftNav fixed bg-primary left-0 top-0 pt-[100px] h-full drop-shadow-2xl z-[12] transition overflow-hidden duration-500 overflow-hidden ");
  const chevron = ref("absolute toggle-menu bg-white rounded-full w-[48px] h-[48px] drop-shadow-md top-1/2 -m-[24px] right-0 cursor-pointer z-20 flex items-center justify-items-center margin");
  const navTabs = ref("nav-item text-left text-white font-extrabold tracking-widest");

  function selectedClass(tab) {
    const isSelected = tab.path == route.path || tab.redirect == route.path;
    return isSelected ? 'navItem active' : 'navItem';
  }

  function  selectedClassChild(child) {
    const isSelected = route.path == child.path;
    return isSelected ? 'navItem active' : 'navItem';
  }
  function toggleExpandedClass(){
    
    let newExpandedVal = isExpandedVal.value;

    if(newExpandedVal)
    {
      expandedClass.value = " isExpanded"
    } 
    else
    { 
      expandedClass.value = " isCollapsed"
    }
    

    leftNav.value = leftNavDefaultClasses.value + expandedClass.value;

    //update isExpandedVal;
    isExpandedVal.value = !isExpandedVal.value;
  }
  function togglenav() {

    emit("emit_toggleNav");
    
    //toggle expandedval
    toggleExpandedClass();
  }
  function isTabChildren(tab) {
    const path = route.path.split("/").filter(Boolean);
    const name = tab.name.split(" ").join("").toLowerCase();
    return path.length > 1 && path.includes(name) && "children" in tab;
  }
  function getTabChildren(tab) {
    
    if (!("children" in tab)) return [];
    const name = tab.name.split(" ").join("").toLowerCase();
    return tab.children.filter(function (item) {
      if(!item.meta.hidden)
        return item.name.split(" ").join("").toLowerCase() != name;
    });
  }

  onMounted(() => {

    nav.value = nav.value.filter((tab) => "component" in tab); // valid components

    // Read Initial Nav State (refresh)
    const navState = store.getters.navState;

    if (navState === undefined || navState === null)
       store.dispatch("mutation", ["navState", {}]);

    for (var i = 0; i < nav.value.length; i++) {
      const navName = nav.value[i].name;
    try {
        if (navName in navState) nav.value[i]["childDrop"] = navState[navName];
        else nav.value[i]["childDrop"] = false;
      } catch (error) {}

    }

    // Transition to New Nav State
    setTimeout(() => {
      var newNavState = {};
      for (var j = 0; j < nav.value.length; j++) {
        const newDrop = isTabChildren(nav.value[j]);
        nav.value[j].childDrop = newDrop;
        newNavState[nav.value[j].name] = newDrop;
      }
      store.dispatch("mutation", ["navState", newNavState]);
    }, 100);

    
    toggleExpandedClass();

  });

</script>

<style scoped>
.navItem
{
  @apply uppercase block w-full transition;
}
.navItem a {  
  @apply flex w-full px-[10px] py-[20px] transition;
}
.bottom-nav-links .navItem a
{
  @apply py-2 text-sm text-white opacity-50 hover:opacity-100;
}
.nav-icon {
  @apply ml-[10px]
}
.nav-text {
  @apply whitespace-nowrap absolute left-[70px]
}

.navItem a:hover {
  @apply bg-secondary/50 
}
.nav-children .navItem {
  @apply normal-case font-normal 
}
.nav-children .navItem a{
  @apply text-sm text-right pl-[80px] py-3
}
.nav-children .navItem a:before{
  @apply text-sm text-right pl-[80px] py-3
}
.nav-children {
  @apply overflow-hidden duration-200
}
.navItem.active {
  @apply bg-secondary
}
.navItem:has(.nav-children .navItem.active) .nav-parent
{
  @apply bg-secondary/50 
}
.leftNav.isCollapsed .bottom-attr
{
  font-size:.50rem;
  line-height: .75rem;
}
</style>