

const { fromZonedTime, toZonedTime, format } = require('date-fns-tz')

  export function toUTCDate(date, offset) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }


  export  function convertUTCDateToLocalDate(date) {    
  
    // Format the date as a string in the local time zone
    var localTimeString = date.toLocaleString();

    // Create a new date object from the local time string
    var localTime = new Date(localTimeString);
  
    return localTime;
  }

  export function convertUTCtoZonedTime(localDate, timeZone)
  {

    return toZonedTime(localDate, timeZone);

  }

  export function convertZonedTimetoUTC(zonedDate, timeZoneOffset)
  {

    const thisOffset = timeZoneOffset ?? 0;
    // Create a Date object with the provided date string
    var datetimeWithOffset = new Date(zonedDate);

    // Get the datetime in milliseconds
    var datetimeMilliseconds = datetimeWithOffset.getTime();

    // Convert the offset from hours to milliseconds
    var offsetMilliseconds = thisOffset * 3600000;

    // Convert the datetime to UTC by subtracting the offset
    var utcTimeMilliseconds = datetimeMilliseconds - offsetMilliseconds;

    // Create a new Date object with the UTC time
    var utcDatetime = new Date(utcTimeMilliseconds);

    // Return the UTC datetime
    return utcDatetime.toISOString();

  }
  
  var getvalidDate = function(d){ return new Date(d) }
  
  export  function validateDateBetweenTwoDates(fromDate,toDate,givenDate){
      return getvalidDate(givenDate) <= getvalidDate(toDate) && getvalidDate(givenDate) >= getvalidDate(fromDate);
  }

  export function getLastDayOfYear(year) {
    return new Date(year, 11, 31);
  }
  
  export function getFirstDayOfYear(year) {
    return new Date(year, 0, 1);
  }

  export function dateComparator(date1, date2) {    
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();
  
    if (date1Number == null && date2Number == null) {
      return 0;
    }
  
    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }
  
    return date1Number - date2Number;
  }