<template>
  
  <div
    style="position: fixed; z-index: 21"
    class="py-9 flex h-[100px]"
    :style="{
      left: isNavExpanded ? '20px' : '100px',
      'transition-duration': transitionSpeed + 's',
    }"
  >
    <DentCloudLogo class="dentlogo w-[100px] sm:w-auto" />
  </div>
  <div>
    <LeftNav
      v-on:emit_toggleNav="emit_toggleNav($event)"
      :navWidth="getNavWidth(0)"
      :transitionSpeed="transitionSpeed"
      :isExpanded="isNavExpanded" 
      :navState="navState"
    />
    <TopNav />
    <!-- <div class="page-content"> -->
    <div
    class="page-content "
      :style="{
        padding: '120px 20px 20px 20px',
        'padding-left': getNavWidth(20) + 'px',
        'transition-duration': transitionSpeed + 's',
      }"
    >
      <!---->
 
      <Breadcrumb class="mb-12" />   

    
    
      <router-view v-slot="{ Component, route }">
  <transition name="fade">
    <component :is="Component" :key="route.path" />
  </transition>
</router-view>
   
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from 'vuex'
const store = useStore();
const meter = computed(() => {return store.getters.meter});


</script>
  <script>
export default {
  data() {
    
    return {
      isNavExpanded: true,
      navExpandBreakpoint: 900,
      maxNavWidth: 260,
      minNavWidth: 60,
      transitionSpeed: .2,
      window: {
          width: 0,
          height: 0
      },
      leftNavClasses:"leftNavWrap"
    };
  },
  created() {
    
    window.addEventListener('resize', this.handleResize);
        this.handleResize();

    if(window.innerWidth > this.navExpandBreakpoint){

    const navExpanded = this.$store.getters.isNavExpanded;

    if (typeof navExpanded == "boolean") this.isNavExpanded = navExpanded;
    } else{
      this.isNavExpanded = false;
      this.$store.dispatch("mutation", [
        "isNavExpanded",
        this.isNavExpanded,
      ]);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    emit_toggleNav() {

      this.isNavExpanded = !this.isNavExpanded;

      this.$store.dispatch("mutation", [
        "isNavExpanded",
        this.isNavExpanded,
      ]);
      
    },
    getNavWidth(add) {
      return add + (this.isNavExpanded ? this.maxNavWidth : this.minNavWidth);
    },
    handleResize() {
        
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        if(this.isNavExpanded && window.innerWidth <= this.navExpandBreakpoint)
        {          
          this.isNavExpanded = false;
            this.$store.dispatch("mutation", [
            "isNavExpanded",
            this.isNavExpanded,
          ]);
        }

    }
  },
  components: {},
};
</script>
  
<style scoped>

    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: opacity 1s;
    }

    .fade-leave-active{
        transition: opacity 1s;
        opacity: 0;
    }
</style> 
