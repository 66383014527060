<!--
    AUTHOR: Ryan Lang
    LATEST UPDATED DATE: 5/31/2024
    DESCRIPTION: Dashboard widget/card that represents an accumulated value according to defined parameter and date/time range values
    ADDITIONAL INFORMATION: //
-->
<template>
              
        <div ref="widgetContent" class="h-full  bg-slate-100/20 p-2 md:p-3">
            
            <div class="view-body h-full flex flex-col justify-between text-center px-1 py-1 xl:px-6 xl:py-4">
            <div class="text-xs font-normal mb-4">{{ Settings.Comment }}</div>
            <span v-if="noData"  class="text-xl text-slate-500">
                    No Data
            </span>
            <span v-else >
                <div class="param-val text-lg xl:text-3xl md:text-2xl sm:text-xl text-slate-500">
                  {{ returnValue }}
                </div>
                <div class="param-uom text-sm text-slate-500">
                  {{dashboardUom}}
              </div>
            </span>

            <div v-for="(param,index) in Settings.Parameters" :key="param.param" class="border border-slate-200 bg-white shadow-md rounded p-2 mt-4">
              <span class="param-name break-all text-xs" v-if="param.displayname">
              {{param.displayname}}
              </span> 
              <span class="param-name break-all text-xs" v-else>
              {{param.param}}
              </span>              
            </div>
          </div>  
        </div>  


</template>

<script setup>

/****external imports ****/
import { ref, computed, onMounted,watchEffect } from "vue";
import { useStore } from 'vuex' 
import { format,addHours } from 'date-fns';
import {convertZonedTimetoUTC, convertUTCDateToLocalDate} from '@/scripts/dateHelpers';
/****external imports ****/

//component property definitions
const props = defineProps({  
  Settings: Array,
  ReloadData: Boolean
});

//reactive variable/object definitions
const store = useStore();
const meter = computed(() => {return store.getters.meter});
const userName = computed(() => {return store.state.loggedinEmail.toLowerCase()});
const noData = ref(false);
const returnValue = ref();
const dashboardUom = ref('');
const triggerReload = computed(() => props.ReloadData);

//emit definitions
const emit = defineEmits(['dataReloaded']);

 //check if prop has been set to true, if so reload data.
 watchEffect(async () => {

    if(triggerReload.value)
    {
      await loadAndProcessWidget();

    }
    
});

//returns the start date/time value of the dashboard time interval
function getTimeIntervalStart(){

    //type should be "value"

    if(props.Settings.TimeInterval) //predefined time intervals shown to end user in user interface
    {
        if(props.Settings.TimeInterval == 'current')  
        {
            return addHours(new Date(),-24).toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastmonth')
        {
            //get last month
            let today = new Date();
                               
            const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);                        

            return addHours(lastDayPrevMonth,-24).toISOString();
        }
        else if(props.Settings.TimeInterval == 'currentmonth')
        {
            //get current month start date
            let today = new Date();
                               
            const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);                        

            return firstDayThisMonth.toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastyear')
        {
            //get last year
            let today = new Date();
                               
            const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);                                    
            return addHours(lastDayPrevYear,-24).toISOString();
            
        }
    }
    else  
        return props.Settings.CustomTimeIntervalStart;  //custom time intervals specified by end user

}


//returns the ending date/time value of the dashboard time interval
function getTimeIntervalEnd(){

    if(props.Settings.TimeInterval)
    {
        if(props.Settings.TimeInterval == 'current')
        {
            return new Date().toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastmonth')
        {
            //get last month
            let today = new Date();                               
            const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);                        
            return lastDayPrevMonth.toISOString();
        }
        else if(props.Settings.TimeInterval == 'currentmonth')
        {
            //get current month last day...
            //should just be current date
            let today = new Date();                               
                                
            return today.toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastyear')
        {
            //get last year
            let today = new Date();                               
            const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);                                         
            return lastDayPrevYear.toISOString();
            
        }
    }
    else 
        return props.Settings.CustomTimeIntervalEnd;        
    
}


//reutrns array of parameters to be used in api call
function getParams(paramObj) {

let params = [];
for (var p of paramObj) params.push(p.param);

return params;  
} 

//loads the data 
async function loadWidgetData(){
        
    const thisMeterOffset = meter.value?.timeZone?.offset
    let thisParams = getParams(props.Settings.Parameters);

    let thisStartDateTime = getTimeIntervalStart();
    let thisStartDateTimeUTC = convertZonedTimetoUTC(thisStartDateTime, thisMeterOffset);

    let thisEndDateTime = getTimeIntervalEnd();
    let thisEndDateTimeUTC = convertZonedTimetoUTC(thisEndDateTime, thisMeterOffset);

    let thisStartDate = format(thisStartDateTimeUTC, "yyyy-MM-dd");
    let thisStartTime = format(thisStartDateTimeUTC, "HH") + ":" + format(thisStartDateTimeUTC, "mm");
    
    let thisEndDate = format(thisEndDateTimeUTC, "yyyy-MM-dd");
    let thisEndTime = format(thisEndDateTimeUTC, "HH") + ":" + format(thisEndDateTimeUTC, "mm");

    var params = {
    request: "getAccumulatedValue",
    username: userName.value,
    metername: meter.value.name,
    range: { 
        startDate: thisStartDate, 
        startTime: thisStartTime, // UTC TIME!
        endDate: thisEndDate, 
        endTime: thisEndTime // UTC TIME!
    },
    topic: thisParams[0], // projection expression
    };

    let thisr = await store.dispatch("s3_API", params);    

    if(thisr.success)
    {
        if(props.Settings.TimeInterval == 'current')
            return thisr.endValue;

        if(thisr.delta)
            return thisr.delta;  
    }
          
    return null;
    
}

//initial call to load the component contents
function loadAndProcessWidget(){
    
    loadWidgetData().then(res => {
        
        
        if(res)
            returnValue.value = res;
        else
            noData.value = true;
        
        emit('dataReloaded', true);

    });
}

onMounted(() => {
    
    
  //call on load
  loadAndProcessWidget();


});

</script>

<style>

</style>