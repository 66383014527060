<template>
  <label class="block relative text-sm select-none cursor-pointer pl-8">{{ name }}
    <input     
      class="absolute cursor-pointer opacity-0 height-0 width-0"
      type="checkbox"
      :value="value"
      v-model="checked"
    />
    <span class="checkmark absolute left-0 top-0 h-[24px] w-[24px] bg-slate-200 rounded-md transition hover:bg-slate-300 after:left-[9px] after:top-[6px] after:w-[5px] after:h-[10px]"></span>
    
  </label>
</template>

<script setup>
  import { watch, ref } from 'vue';
  
  const props = defineProps({
    modelValue: Array,
    value: String
  });
  
  const name = ref('');

  if(props.value.name)
  {
    name.value = props.value.name;
  }
  else{
    name.value = props.value;
  }

  const checked = ref(props.modelValue.includes(props.value));

  
  
  watch(checked, (v) => {
    if(!v && props.modelValue.includes(props.value)) {
      const toRemove = props.modelValue.indexOf(props.value);
      props.modelValue.splice(toRemove, 1);
    } else if(v && !props.modelValue.includes(props.value)) {
      props.modelValue.push(props.value);
    }
  });
  
  watch(props.modelValue, (mv) => {
    if(checked.value && !mv.includes(props.value)) {
      checked.value = false;
    } else if(!checked.value && mv.includes(props.value)) {
      checked.value = true;
    }
  });

</script>


<style scoped>

/* When the checkbox is checked, add a blue background */
label input:checked ~ .checkmark {
  @apply bg-secondary
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
label .checkmark:after {

  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>