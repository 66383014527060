<template>

  <div
    class="text-slate-400 flex"
    style="margin-left: 10px; margin-bottom: 20px"
  >
    <div class="breadcrumb" v-for="route in breadRoutes" :key="route.path">
      <span v-if="!route.path" class="text-primary/50">{{route.name}} <button @click="() => {isShowActiveMeterInformationModalOpen = true}"><InformationCircleIcon class="w-4 h-4 p-0" /></button></span>
      <RouterLink v-else :to="route.path" class="breadcrumb-item">{{
        route.name
      }}</RouterLink>
    </div>
  </div>
  

  <GDialog v-model="isShowActiveMeterInformationModalOpen">     
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex flex-col min-h-full text-center justify-center items-center ">          
          <div class="w-full  overflow-y-auto bg-white max-w-4xl transform overflow-hidden rounded-lg p-6 text-left align-middle shadow-md transition-all">
            <h2 class="font-bold mb-2">Meter Information</h2>
            
            <div class="meter-information text-sm mb-4">
              <div class="flex flex-wrap">
                <div class="serialnumber pr-4 w-1/2">
                  <span class="font-bold pr-1">S/N:</span>{{ meter.name }}
                </div>
                <div class="timezone pr-4 w-1/2">
                  <span class="font-bold pr-1">Time Zone:</span> {{ meter.timeZone.text }}
                </div>
                <div class="pr-4 w-1/2">
                  <span class="font-bold pr-1">Name:</span> {{ meter.customName }}
                </div>
                <div class="description break-all pr-4 w-1/2">
                  <span class="font-bold pr-1 break-all">Description:</span>{{ meter.descr }}
                </div>
                <div class="site pr-4 w-1/2">
                  <span class="font-bold pr-1">Location:</span>{{ meter.site }}
                </div>
              </div>
            </div>

            <button
              type="button"
              class="
                inline-flex
                justify-center
                rounded-md
                border border-transparent
                bg-blue-100
                px-4
                py-2
                text-sm
                font-medium
                transition
                text-blue-900
                hover:bg-blue-200
                focus:outline-none
                focus-visible:ring-2
                focus-visible:ring-blue-500
                focus-visible:ring-offset-2
              "
              @click="() => {isShowActiveMeterInformationModalOpen = false}"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </GDialog> 

</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from 'vuex'
import { useRoute,useRouter } from 'vue-router'
const store = useStore();
const thisRoute = useRoute();
const router = useRouter();
const meter = computed(() => {return store.getters.meter});
const isShowActiveMeterInformationModalOpen = ref(false);
 
import { HomeIcon, InformationCircleIcon } from "@heroicons/vue/20/solid";

const breadRoutes = ref([]);

const getAllRoutes = (routes) => {
  let paths = [];
  for (const route of routes) {
    paths.push(route);
    if ("children" in route) {
      paths = paths.concat(getAllRoutes(route.children));
    }
  }
  return paths;
};

const myRoutePath = thisRoute.path.split("/").filter(Boolean);
const routes = router.options.routes;
const paths = getAllRoutes(routes);

for (let r = 0; r < paths.length; r++) {

  if (paths[r].path == "/") continue;

  for (let i = 0; i < myRoutePath.length; i++) {

    let route = "";

    for (let p = 0; p <= i; p++) {
      route += "/" + myRoutePath[p];
    }

    if (paths[r].path == route) breadRoutes.value.push(paths[r]);
  }
}

if(thisRoute.meta.showActiveMeter)
{
  let cRoute = {
    "path": "",
    "name": meter.value.name,
    "meta": {
        
    }
}
  
  breadRoutes.value.splice(1,0,cRoute);
}

</script>

<style>
.breadcrumb-item {

}
.breadcrumb-item.router-link-active{
  font-weight: bold;
  cursor: default;
  
}
.breadcrumb:not(:last-child):after {
  display: inline;
  content: "/";
  padding: 0 8px;
}
.breadcrumb-item:hover:not(.router-link-active) {
  @apply text-textlink
  
  /* margin-right: -6.5px; */
}
</style>
