<template>
   

    <div class="childnav">
        <h2 class="text-primary font-bold p-4">Settings</h2>
      <ul  class="mt-6">
        
          <li v-for="tab in childRoutes" :key="tab.name" class="noselect transition text-sm uppercase ">
            <router-link :to="'redirect' in tab ? tab.redirect : tab.path" class="py-3 pl-4 pr-10 block border-l-4 border-l-primary/[0]">              
              <span class="">{{ tab.name }}</span>
            </router-link>                
          </li>
        
      </ul>
    </div>
 
  </template>
  
  
  
  <script setup>
  import { getCurrentInstance } from 'vue';
  import { useRouter,useRoute } from 'vue-router'
    

    const route = useRoute();
    const router = useRoute();
    const thisRoute = router.matched[0];
    const childRoutes = thisRoute.children;



  /*
  props: {
      navWidth: {
        type: Number,
        default: 0,
      },
      isExpanded: {
        type: Boolean,
        default: false,
      },
      transitionSpeed: {
        type: Number,
        default: 0,
      },
      navState: {
        type: Object,
        default: {},
      },
    }
    
    
     nav: withNavPages,
        bottomNav: bottomNavigationPages,
        leftnav:
          "navExpand fixed bg-primary left-0 top-0 pt-[100px] h-full drop-shadow-2xl z-[12] transition overflow-hidden duration-500 overflow-hidden",
        chevron:
          "absolute toggle-menu bg-white rounded-full w-[48px] h-[48px] " +
          "drop-shadow-md top-1/2 -m-[24px] right-0 cursor-pointer z-20 flex " +
          "items-center justify-items-center margin",
        navTabs:
          "nav-item text-left text-white font-extrabold tracking-widest",

          selectedClass(tab) {
        const isSelected = tab.path == this.$route.path || tab.redirect == this.$route.path;
        return isSelected ? 'navItem active' : 'navItem';
      },
      selectedClassChild(child) {
        const isSelected = this.$route.path == child.path;
        return isSelected ? 'navItem active' : 'navItem';
      },
      leftNav(isExpanded) {
        var leftnav =
          "left-nav transition fixed bg-primary left-0 top-0 h-full drop-shadow-2xl ";
        leftnav += isExpanded ? "min-w-[260px]" : "min-w-[0px]";
        return leftnav;
      },
      togglenav() {
        this.$emit("emit_toggleNav", {});
      },
      navHover() {
        return this.isExpanded ? "8px" : "-1px";
      },
      isTabChildren(tab) {
        const path = this.$route.path.split("/").filter(Boolean);
        const name = tab.name.split(" ").join("").toLowerCase();
        return path.length > 1 && path.includes(name) && "children" in tab;
      },
      getTabChildren(tab) {
        if (!("children" in tab)) return [];
        const name = tab.name.split(" ").join("").toLowerCase();
        return tab.children.filter(function (item) {
          return item.name.split(" ").join("").toLowerCase() != name;
        });
      },


          async created() {
      
      this.nav = this.nav.filter((tab) => "component" in tab); // valid components
  
      // Read Initial Nav State (refresh)
      const navState = this.$store.getters.navState;
      if (navState === undefined || navState === null)
        await this.$store.dispatch("mutation", ["navState", {}]);
  
      for (var i = 0; i < this.nav.length; i++) {
        const navName = this.nav[i].name;
        try {
          if (navName in navState) this.nav[i]["childDrop"] = navState[navName];
          else this.nav[i]["childDrop"] = false;
        } catch (error) {}
  
      }
  
      // Transition to New Nav State
      setTimeout(() => {
        var newNavState = {};
        for (var j = 0; j < this.nav.length; j++) {
          const newDrop = this.isTabChildren(this.nav[j]);
          this.nav[j].childDrop = newDrop;
          newNavState[this.nav[j].name] = newDrop;
        }
        this.$store.dispatch("mutation", ["navState", newNavState]);
      }, 100);
  
  
  
    },

    */


  </script>
  
  <style scoped>
  
  .router-link-active
  {
    background-color: #fff;
    @apply border-l-primary border-l-4
  }

  </style>