<!--
    AUTHOR: Ryan Lang
    LAST UPDATE: 7/14/2023
    DESCRIPTION: Component to show the users avatar in the upper right of the top nav
    ADDITIONAL INFORMATION: Originally intended to be used to show account user's avatar.
-->
<template>
    <div :style="avatarStyles" class="vue-avatar">
      <template v-if="!slots.default && !imgSrc">
        {{ parsedUserName }}
      </template>
      <img v-if="imgSrc.length" :src="imgSrc" :alt="imgAlt" />
      <slot />
    </div>
  </template>
  
  <script setup>
  import { computed, useSlots,ref } from "vue";
  import { useStore } from 'vuex'
  const store = useStore();
  const userProfile = computed(() => { return store.state.userProfile});
  const userProfileImg = computed(() => { return store.state.userProfile.imgData});


  const imgSrc = computed(() => {
   
    if(userProfile.value && userProfileImg.value)
      return userProfileImg.value;
    else
      return "";
  });

  /**
   * Component props object
   */
  const props = defineProps({

    size: { 
      type:Number,
      default:35
    },
    borderRadius: { 
      type:String,
      default:"100%"
    },
    backgroundColor:{ 
      type:String,
      default:"#20345a"
    },
    color:{ 
      type:String,
      default:"#ffffff"
    },

  });

  /**
   * Computed return uppercase letters from username
   */
    const parsedUserName = computed(() => {

      if(Object.is(userProfile.value ?? null, null))
        return '';
      else{

        let thisFirst = "";
        let thisLast = "";
        
        if(Object.is(userProfile.value.firstname ?? null, null))
          thisFirst = "";

        if(Object.is(userProfile.value.lastname ?? null, null))
          thisLast = ""

        let username = (userProfile.value.firstname + " " + userProfile.value.lastname); // Ensure username is not null or undefined

        if(username.length > 0)
        {
          const matches = username.match(/[A-ZА-ЯЁ]/g);
          if (!matches) {
              return ""; // Return empty string if no matches found
          }

          return matches.slice(0, 3).join("");
        }
        else 
          return "";

      }
    });
  
  /**
   * Computed return components styles
   */
  const avatarStyles = computed(() => {
    return {
      width: `${props.size}px`,
      height: `${props.size}px`,
      borderRadius: props.borderRadius,
      fontSize: `${props.size / 2.2}px`,
      backgroundColor: backgroundColor.value,
      color: props.color
    };
  });
  
  
  /**
   * Generate background color depends on username
   */
   const backgroundColor = (() => {
  let lastUsername = null;
  let lastColor = null;

  return computed(() => {
    if (props.username === lastUsername) {
      return lastColor;
    }

    if (props.backgroundColor) {
      lastUsername = props.username;
      lastColor = props.backgroundColor;
      return lastColor;
    }

    let hash = 5381;
    for (let i = 0; i < props.username.length; i++) {
      hash = (hash * 33) ^ props.username.charCodeAt(i);
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }

    lastUsername = props.username;
    lastColor = color;
    return color;
  });
})();

  
  const slots = useSlots();

  </script>
  
  <style  scoped>
  .vue-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
    .vue-avatar img, .vue-avatar picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      
    }
  
  </style>