import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import { http_GatewayAPI, http_AbortRequest } from '@/scripts/gatewayAPI.js';
import * as cognito from '@/scripts/cognito.js';
import { isValid } from '@/scripts/validation.js';
import router from '@/framework/router';
var CryptoJS = require("crypto-js");

const SECRET_KEY = process.env.VUE_APP_ENCRYPTION_KEY;
const SALT = "U2FsdGVkX1";
function encryptStore(encrypt) {
  encrypt = JSON.stringify(encrypt);
  var crypto = CryptoJS.AES.encrypt(encrypt, SECRET_KEY).toString().substring(10);
  crypto = crypto.split("").reverse().join("");
  return crypto;
}
function decryptStore(decrypt) {
  decrypt = decrypt.split("").reverse().join("");
  decrypt = SALT + decrypt;
  var crypto = CryptoJS.AES.decrypt(decrypt, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(crypto);
}

const defaultState = {
  debugMode: true,
  ipAddress: null,
  jwtToken: null,
  isNavExpanded: null,
  loggedinEmail: null,
  loginExpired: null,
  userProfile: {},
  meter: {},
  navState: {},
  currentDashboard: {},
  metersList:[]
}

const store = createStore({
  plugins: [
    createPersistedState({ storage: window.sessionStorage }),
  ],
  state: {},
  actions: {
    resetState({ commit }) { commit("resetState") },
    expireState({ commit }) { commit("resetState"); commit("rejectState"); },
    mutation(state, params) { state.commit('mutation', params) },

    /* Request Local Storage ---------------------------------------------- ** */
    async requestLocalStorage(state) {
      for (var i = 0; i < localStorage.length; i++) {
        const localKey = localStorage.key(i);
        const keySplit = localKey.split('.');
        if (keySplit[0] == "dentcloud") {
          const value = decryptStore(localStorage.getItem(localKey));
          state.commit('mutation', [keySplit[1], value]);
        }
      }
      const isLoggedIn = state.getters.loggedinEmail;
      return !(typeof isLoggedIn == 'undefined' || isLoggedIn == null || isLoggedIn == "");
    },

    /* Clear Local Storage ------------------------------------------------ ** */
    async clearLocalStorage(state) {
      state.commit('resetState');
    },

    /* Log In to Cognito Service ------------------------------------------ ** */
    async cognito_logIn(state, params) {

      const token = 'jwtToken' in state.getters ? await state.getters.jwtToken : "";
      if (isValid(token)) return { success: true, token: token };

      state.commit('mutation', ['loginExpired', false]);
      const login = await cognito.logIn(); // TODO: Implement actual user login
      if (login.success) state.commit('mutation', ['jwtToken', login.token]);
      return { success: login.success, token: login.token };
    },

    /* Sign Out of Cognito Service ---------------------------------------- ** */
    async cognito_signOut(state) {
      const signOut = await cognito.signOut();
      await state.dispatch("resetState");
      state.commit('mutation', ['loginExpired', false]);
      return { signOut: signOut, reset: state.state };
    },

    /* Cognito Change Password (logged in) --------------------------------- ** */
    // async cognito_changePassword(state, params) {
    //   const oldPW = 'oldPassword' in params ? params.oldPassword : '';
    //   const newPW = 'newPassword' in params ? params.newPassword : '';
    //   if (oldPW != newPW && oldPW != '' && newPW != '')
    //     return await cognito.changePassword(oldPW, newPW);
    //   else return { success: false, error: 'bad inputs' };
    // },

    /* Cognito request email password change ------------------------------- ** */
    async cognito_forgotPassword(state, params) {
      const username = 'username' in params ? params.username : '';
      if (username != '')
        return await cognito.forgotPassword(username);
      else return { success: false, error: "bad username" };
    },

    /* Cognito confirm password from email code ---------------------------- ** */
    async cognito_forgotPasswordSubmit(state, params) {
      const un = 'username' in params ? params.username : '';
      const code = 'code' in params ? params.code : '';
      const npw = 'newPassword' in params ? params.newPassword : '';
      if (un != '' && code != '' && npw != '')
        return await cognito.forgetPasswordSubmit(un, code, npw);
      else return { success: false, error: "bad parameters" };
    },

    /* http GET request to DENTCloud -------------------------------------- ** */
    async dentcloud_API(state, params) {
      
      async function call_dentcloudAPI(params, ip, jwt) {
        const readyState = await new Promise((resolve, reject) =>
          http_GatewayAPI(params, ip, jwt,
            function (response) { resolve(response) }));

        var response = { success: false };
        try {
          response = await readyState;
        } catch (error) {
          response.error = error
          response.success = false;
        }
        return response; // wait for callback
      }
      const ip = await state.dispatch("getIpAddress");
      const jwt = await state.dispatch("getJWT");

      const response = await call_dentcloudAPI(params, ip, jwt);
      if (
        typeof response === 'object' &&
        !Array.isArray(response) &&
        response !== null
      ) { return response; }
      else if (await cognito.parseResponse(response)) return response;
      else return { success: false, error: "response data type" }
    },

    /* request to authorized file access ---------------------------------- ** */
    async s3_API(state, params) {
      const filename = 'filename' in params ? params.filename : 'default/default.txt';
      const content = 'content' in params ? params.content : 'NO CONTENT';
      const metername = 'metername' in params ? params.metername : 'NO METERNAME';
      const dayRange = 'dayRange' in params ? params.dayRange : [];
      // console.log(`${JSON.stringify(params)}`)

      if (params.request == "PUT") return await cognito.s3_PUT(filename, content); //bool
      else if (params.request == "GET") return await cognito.s3_GET(filename);
      else if (params.request == "s3_DATA") return await cognito.s3_DATA(params);
      else if (params.request == "getAccumulatedValue") return await cognito.s3_getAccumulatedValue(params);
      // else if (params.request == "DATA_OLD") return await cognito.s3_DATA_old(metername, dayRange);
      else {
        // console.log("not sure", params);
        return false;
      } 
      return false;
    },

    /* Abort HTTP Request -------------------------------------- ** */
    async dentcloud_Abort(state) {
      const abort = await http_AbortRequest();
      return abort;
    },

    /* Fetch the IP Address for encryption method ------------------------ ** */
    async getIpAddress(state) { // for gatewayAPI encryption
      async function requestIP() { // only request IP address once
        const { ip } = await fetch("https://api.ipify.org?format=json",
          { method: "GET" })
          .then((res) => res.json())
          .catch((error) => console.error(error));
        return ip ?? "Unknown";
      }
      if ('ipAddress' in state.getters && isValid(state.getters.ipAddress))
        return state.getters.ipAddress; // Value already exists

      const newIp = await requestIP();
      if (newIp != "Unknown") state.commit('mutation', ['ipAddress', newIp]);
      return newIp;
    },

    /* Get and maintain JSON Web Token ------------------------------------ ** */
    async getJWT(state) {
      if ('jwtToken' in state.getters && isValid(state.getters.jwtToken))
        return state.getters.jwtToken;
    },

  },
  mutations: { // Allows for dynamic creation of state values
    mutation(state, payload) {
      if (Array.isArray(payload) && payload.length == 2) {
        state[payload[0]] = payload[1];
        localStorage.setItem('dentcloud.' + payload[0], encryptStore(payload[1]));
      }
    },
    resetState(state) {
      for (const prop in state) {
        if (prop != "loginExpired") state[prop] = null;
        localStorage.removeItem('dentcloud.' + prop);
      }
    },
    rejectState(state) {
      state.loginExpired = true;
      router.push('/login');
      cognito.signOut();
    }
  },
  getters: Object.keys(defaultState).reduce(
    (a, key) => Object.assign(a, { [key]: new Function('state', 'return state.' + [key]) }), {}
  ),
  modules: {},
})

export default store