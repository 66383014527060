export function isNullOrEmpty(str) {
    // Check if the string is null or undefined
    if (str === null || str === undefined) {
        return true;
    }
    
    // Check if the string is empty after trimming leading and trailing whitespace
    if (typeof str === 'string' && str.trim() === '') {
        return true;
    }
    
    // If none of the above conditions are met, the string is not null, undefined, or empty
    return false;
}