import { LifebuoyIcon, AdjustmentsVerticalIcon,PlusIcon,Squares2X2Icon, MinusSmallIcon, Cog6ToothIcon } from "@heroicons/vue/20/solid";


const meterPages = [
    {
        path: '/mymeters',
        name: 'My Meters',
        component: () => import("@/views/meters/mymeters/MyMeters.vue"),
        meta: {
            requiresAuth: true,
         },
    },
    {
        path: '/mymeters/managemeter',
        name: 'Meter Dashboard',
        component: () => import("@/views/meters/managemeter/ManageMeter.vue"),
        meta: {
            requiresAuth: true,
            showActiveMeter:true
         },
    },
    {
        path: '/mymeters/managemeter/meterdata',
        name: 'Meter Data',
        icon: MinusSmallIcon,
        component: () => import("@/views/meters/meterdata/MeterData.vue"),
        meta: {
            requiresAuth: true,
            showActiveMeter:true
         },
    },
    {
        path: '/mymeters/managemeter/viewdatawidget',
        name: 'View Widget',
        component: () => import("@/views/dashboards/view-dashboard.vue"),
        meta: {
            requiresAuth: true,
            hidden:true,
            showActiveMeter:true
         },
    
    },
    /* {
        path: '/mymeters/managemeter/exportdata',
        name: 'Export Data',
        icon: MinusSmallIcon,
        component: () => import("@/views/meters/meterdata/ExportData.vue"),
        meta: {
            requiresAuth: true,
         },
    },*/
] 


export const withNavPages = [
/*     Removed for v1.1.  To be implemented in the future.
{
        name: 'Dashboard',
        path: '/dashboards',
        component: () => import("@/views/dashboards/globaldashboards.vue"),
        icon: Squares2X2Icon,
        meta: {
            requiresAuth: true,
         },
    }, */  
    {
        name: 'My Meters',
        path: '/MeterRoot',
        redirect: '/mymeters',
        component: () => import("@/views/meters/Meters.vue"),
        children: meterPages,
        icon: AdjustmentsVerticalIcon,
        meta: {
            requiresAuth: true,
            pageTitle: 'Your Meters',
         },
    },
    {
        name: 'Add Meter',
        path: '/add-meter',
        component: () => import("@/views/meters/addmeter/AddMeter.vue"),
        icon: PlusIcon,
        meta: {
            requiresAuth: true,
            pageTitle: 'Add Meter',
         },
    },
    {
        name: 'Support',
        path: '/support',
        component: () => import("@/views/support/support.vue"),
        icon: LifebuoyIcon,
        meta: {
            requiresAuth: true,
            pageTitle: 'Support',
         },
    }
    
]
    


export const bottomNavigationPages = [

    {
        name: 'Settings',    
        path: '/settings',   
        component: () => import("@/components/page/settings.vue"),        
        icon: Cog6ToothIcon,
        meta: {
            requiresAuth: true,
            pageTitle:'Settings'
         },
    },
    
]
